import { animated, useSpring, easings } from '@react-spring/web'

const animations = {
  fadeIn: {
    from: { opacity: 0 /*, transform: 'translate3d(0px,0px,0)'*/ },
    to: { opacity: 1 /*, transform: 'translate3d(0px,0px,0)' */ },
    //config: { tension: 500, friction: 50, clamp: true },
    config: { easing: easings.easeInExpo },
  },
}
export const useFadeIn = () => {
  const spring = useSpring(animations.fadeIn)
  return {
    animated,
    spring,
  }
}
