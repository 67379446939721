/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/05/2023.
 */
import React from 'react'

export const DeviceDetailsTrialStaff = () => {
  return (
    <>
      <div className="col-12 my-3">Device management is restricted to clinicians.</div>
    </>
  )
}
