import React from 'react'
import { PROJECT_STATES, TOKEN_USER_TYPES } from '../Types'
import { ListItemContent } from './display/ListItemContent'
import { Link, useParams } from 'react-router-dom'
import { Button } from 'antd'
import formatDistance from 'date-fns/formatDistance'

import { ReactComponent as UserIcon } from '../assets/user.svg'
import { differenceInDays } from 'date-fns'
import { authStore } from '../store/auth/authStore'

type Props = {
  attendToSubjects: any //SubjectType[]
  nonCompliant: any //SubjectWithAnswers[]

  project: any
}

export const AttendToSubjectsList = ({ attendToSubjects, nonCompliant, project }: Props) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const user: any = authStore((state) => state.user)
  const userType = user?.attributes['custom:userType']
  //  console.log('AttendToSubjectsList > nonCompliant = ', nonCompliant[0])

  const now = new Date()
  now.setHours(0, 0, 1, 0)

  return (
    <div className="row" style={{}}>
      {attendToSubjects.length > 0 && nonCompliant.length > 0 ? (
        <div className="col-12 py-4">
          <h6 className="fw-bold ">Attend to Subject</h6>
        </div>
      ) : null}

      {attendToSubjects.map((subject, index) => {
        const deviceState = subject.device
          ? subject.device.state === 'WIPED'
            ? 'Wiped device'
            : 'Enroll device'
          : 'N/A'

        return (
          <div key={index} className="col-12 mb-1">
            <div
              className="bg-white d-flex align-items-center py-2 px-3 "
              style={{
                borderBottom: '1px solid rgba(0,0,0,.1)',
                boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div className="me-3 ms-2 ">
                <UserIcon style={{ width: 30, height: 30 }}></UserIcon>
              </div>

              <ListItemContent
                label="Screening id:"
                title={subject.displayId}
                style={{ width: '20%' }}
              ></ListItemContent>

              <ListItemContent label="status" title={deviceState} style={{ width: '30%' }} type="danger" />
              <ListItemContent label="" title="" style={{ width: '30%' }} type="danger" />

              {userType === TOKEN_USER_TYPES.CLINICIAN ? (
                <div className="d-flex justify-content-end flex-fill " style={{ width: '10%' }}>
                  <Link to={`/project/${projectId}/site/${siteId}/subject/onboard/${subject._id}`}>
                    <Button
                      type="primary"
                      disabled={project.status === PROJECT_STATES.DRAFT || project.status === PROJECT_STATES.RETIRED}
                    >
                      Assign device
                    </Button>
                  </Link>
                </div>
              ) : (
                <div className="d-flex justify-content-end flex-fill " style={{ width: '20%' }}>
                  <Link to={`/project/${projectId}/subject/view/${subject._id}`}>
                    <Button type="primary">View Subject</Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )
      })}
      <div className="my-3"></div>
      {nonCompliant.map((subject, index) => {
        // the backend should return the last answer
        //console.log('AttendToSubjectsList > subject = ', subject)
        const lastAnswer = subject.answers[subject.answers.length - 1]
        const currentPhase = subject.currentPhase?.length > 0 ? subject.currentPhase[0] : {}

        const phaseTitle = currentPhase ? currentPhase.title : 'N/A'
        const phaseOverallCompliance = currentPhase?.overallCompliance ? currentPhase?.overallCompliance : 0
        const phaseShortCompliance = currentPhase?.shortCompliance ? currentPhase?.shortCompliance : 0

        const agoInDays = lastAnswer ? differenceInDays(now, new Date(lastAnswer.createdAt)) : 0

        const subjectOverallComplience = subject.overallCompliance ? subject.overallCompliance : 0

        const inOverallComplience = subjectOverallComplience > phaseOverallCompliance

        const inShortComplience = agoInDays < phaseShortCompliance

        //console.log('AttendToSubjectsList > = ', subject.displayId, inOverallComplience, inShortComplience)

        const ago = lastAnswer ? formatDistance(new Date(lastAnswer.createdAt), new Date(), {}) : 'N/A'

        if ((inShortComplience && inOverallComplience) || subjectOverallComplience === -1) {
          return null
        }

        return (
          <div key={index} className="col-12 mb-1">
            <div
              className="bg-white d-flex align-items-center py-2 px-3 "
              style={{
                borderBottom: '1px solid rgba(0,0,0,.1)',
                boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div className="me-3 ms-2 ">
                <UserIcon style={{ width: 30, height: 30 }}></UserIcon>
              </div>

              <ListItemContent
                label="Screening id:"
                title={subject.displayId}
                style={{ width: '20%' }}
              ></ListItemContent>

              <ListItemContent label="Status" title={phaseTitle} style={{ width: '20%' }} />
              <ListItemContent
                label="last activity"
                title={ago}
                type={!inShortComplience ? 'danger' : ''}
                style={{ width: '20%' }}
              />
              <ListItemContent
                label="Overall compliance"
                //title={`${subjectOverallComplience}% required ${phaseOverallCompliance}%`}
                title={''}
                comp={
                  <p className={!inOverallComplience ? 'text-danger' : ''}>
                    <span className="fw-bold">{subjectOverallComplience}%</span>{' '}
                    {/* <span className="opacity-75">allowed</span>{' '}
                    <span className="fw-bold">{phaseOverallCompliance}%</span>*/}
                  </p>
                }
                style={{ width: '20%' }}
              />

              {/*<ListItemContent
                label="compliance"
                title={'---'}
                comp={
                  <div className="">
                    <p className="p-small">
                      {inShortComplience ? 'No activity for ' + phaseShortCompliance + ' days ' : ''}
                    </p>
                    <p className="p-small">{!inOverallComplience ? 'below ' + phaseOverallCompliance + '%' : ''}</p>
                  </div>
                }
                style={{ width: '20%' }}
              />*/}
              {/*<ListItemContent label="Latest answer" title={ago} style={{ width: '20%' }} />*/}

              {userType === TOKEN_USER_TYPES.CLINICIAN ? (
                <div className="d-flex justify-content-end flex-fill " style={{ width: '20%' }}>
                  <Link to={`/project/${projectId}/site/${siteId}/subject/view/${subject._id}`}>
                    <Button type="primary">View Subject</Button>
                  </Link>
                </div>
              ) : (
                <div className="d-flex justify-content-end flex-fill " style={{ width: '20%' }}>
                  <Link to={`/project/${projectId}/subject/view/${subject._id}`}>
                    <Button type="primary">View Subject</Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
