import { Button, Modal, Popconfirm } from 'antd'
import { SetStateAction, useState } from 'react'
import { useParams } from 'react-router'
import { getReq } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { SideBarSiteListItem } from '../trailstaff/SideBarSiteListItem'
import { useUserSites } from '../../hooks/useUserSites'

type SubjectExportTypes = {
  isModalOpen: boolean
  setIsSubjectExportOpen: (e: SetStateAction<boolean>) => void
}

export const SubjectExportModal = ({ isModalOpen, setIsSubjectExportOpen }: SubjectExportTypes) => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useUserSites(projectId)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)

  const [localSitesIds, setLocalSiteId] = useState<any>(data.map((site) => site._id))

  const selectSiteClick = (siteId: string) => {
    const exist = localSitesIds.indexOf(siteId)

    if (exist === -1) {
      setLocalSiteId(localSitesIds.concat([siteId]))
    } else {
      const filtred = localSitesIds.filter((item) => item !== siteId)
      setLocalSiteId(filtred)
    }
  }

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    return getReq(ENDPOINTS.SUBJECTS.exportSelectedAssignments(projectId, localSitesIds))
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a')
        link.href = url

        const today = new Date()
        const yyyy = today.getFullYear()
        let mm = today.getMonth() + 1 // Months start at 0!
        let dd = today.getDate()

        const formattedToday = dd + '/' + mm + '/' + yyyy

        const flleName = 'subjects-' + formattedToday + '.csv'
        link.setAttribute('download', flleName)

        document.body.appendChild(link)
        link.click()

        // Clean up and remove the link
        link.parentNode?.removeChild(link)

        setIsSubjectExportOpen(false)
        setConfirmLoading(false)
        setOpenPop(false)
      })
      .catch((error) => {
        alert(error)
        setIsSubjectExportOpen(false)
        setConfirmLoading(false)
        setOpenPop(false)
      })
  }

  const hideModal = () => {
    setIsSubjectExportOpen(false)
  }

  const handleExportOk = () => {
    setIsSubjectExportOpen(false)
  }

  const handleExportCancel = () => {
    setIsSubjectExportOpen(false)
  }

  const cancel = () => {
    setOpenPop(false)
  }

  return (
    <>
      <Modal
        title={[<div key={1}>Subject data export</div>]}
        open={isModalOpen}
        onOk={handleExportOk}
        onCancel={handleExportCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal}>Cancel</Button>
            <Popconfirm
              title="Are you sure you would like to export data for all subjects from the selected sites?"
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Export"
              cancelText="Cancel"
            >
              <Button className="ms-2" type="primary" onClick={showPopconfirm}>
                Export subject data
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <div className="container-fluid p-0">
          <div className="row rounded-2" style={{ background: '#f3f3f3' }}>
            <div className="col-12 py-4 mb-2">
              <p className="fw-bold mb-2"></p>
              <p>Please select from the list below which sites you want to extract subject data from:</p>
            </div>
          </div>
          <div className="row mt-1 mb-4">
            {data.map((site) => {
              const isChecked = localSitesIds.indexOf(site._id) > -1 ? true : false

              return (
                <SideBarSiteListItem
                  key={site._id}
                  site={site}
                  selectSiteClick={selectSiteClick}
                  isChecked={isChecked}
                ></SideBarSiteListItem>
              )
            })}
          </div>
        </div>
      </Modal>
    </>
  )
}
