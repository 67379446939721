/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/09/2023.
 */
import React, { useEffect } from 'react'
import { Button } from 'antd'

type Props = {
  formHook: any
  nextIndex: number
  prevIndex: number
  setPageIndex: (index: number) => void
  onSubmit: (formData: any) => void
}

export const Confirmation = ({ formHook, prevIndex, nextIndex, setPageIndex, onSubmit }: Props) => {
  const {
    unregister,
    getValues,
    //formState: { errors },
  } = formHook

  const formValues = getValues()

  const prev = () => {
    setPageIndex(prevIndex)
  }

  console.log('Confirmation >     formValues = ', formValues)
  const phone =
    formValues?.phone?.countryCode && formValues?.phone?.tel
      ? formValues?.phone?.countryCode + ' ' + formValues?.phone?.tel
      : false

  const email = formValues.email

  console.log('Confirmation > phone = ', phone)
  //
  // To make the form not complain about missing fields, we need to unregister the field that is not used
  //
  // If phone is set, then remove email from form
  // If email is set, then remove phone from form
  //
  useEffect(() => {
    if (phone && !email) {
      unregister('email')
    }
    if (!phone && email) {
      unregister('phone')
    }
  }, [phone, email, unregister])

  return (
    <div className="row ">
      <div className="col-8 mx-auto mt-6 ">
        <div className="row bg-white border block-shadow p-5" style={{}}>
          <div className="col-12">
            <h5 className="">Please Confirm Subject Details</h5>
            <p>
              Please review and confirm the subject details. Ensuring accurate details is essential for compliance and
              for enabling functionalities to work.
            </p>
            <hr />
          </div>

          <div className="col-12 ">
            <div className="px-6 py-4 " style={{ background: '#efefef' }}>
              <Detail label="Screening ID" value={formValues.screeningId}></Detail>
              <Detail label="Name" value={formValues.name}></Detail>
              <Detail label="Email" value={formValues.email || '-'}></Detail>
              <Detail label="Language" value={formValues.language || '-'}></Detail>
              <Detail label="Phone" value={phone || '-'}></Detail>
            </div>
          </div>
        </div>
        {/*<div className="col-12 mt-4">
          <Alert showIcon message={'errorState.type'} description={'errorState.message'} type="error" />
        </div>*/}
      </div>

      <div className="col-12 d-flex justify-content-center mt-4">
        <Button className="mx-1" type="default" onClick={prev}>
          Back
        </Button>
        <Button className="mx-1" type="primary" onClick={onSubmit}>
          Confirm details
        </Button>
      </div>
    </div>
  )
}

const Detail = ({ value, label }) => {
  return (
    <div className="mt-4">
      <p className="">{label}</p>
      <h4 className="fw-bold " style={{ wordBreak: 'break-all' }}>
        {value}
      </h4>
    </div>
  )
}
