/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2023.
 */
import React, { Suspense } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useProjectById } from '../../hooks/useProjectById'
import { TraningModal } from '../../components/TrainingModal'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useMe } from '../../hooks/useMe'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useSubjectAttention } from '../../hooks/useSubjectAttention'
import { ProjectPhaseListItem } from './ProjectPhaseListItem'
import { ProjectQuestionnaireDrawer } from './ProjectQuestionnaireDrawer'
import { useFadeIn } from '../../components/animation/useFadeIn'
import { AttendToSubjectsList } from '../../components/AttendToSubjectsList'
import { PageHeader } from '../../components/display/PageHeader'

export const ProjectViewClinician = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const { data } = useProjectById(projectId)
  const { data: protocol } = useByEndpoint(ENDPOINTS.PROTOCOLS.byProjectId(projectId))
  const { data: subjects } = useSubjectAttention(projectId, siteId)
  const { data: me, mutate: mutateUser } = useMe(projectId)

  const attendToSubjects = subjects?.subjects || []
  const nonCompliant = subjects?.nonCompliant || []

  const userTraining = me?.training || -1
  const showTrainingModal: boolean = data?.training > userTraining

  const { animated, spring } = useFadeIn()

  const closeDrawer = () => {}

  return (
    <>
      <Routes>
        <Route
          path="/questionnaire/:questionnaireId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer
                projectId={projectId}
                setVisible={closeDrawer}
                width={500}
                to="../"
                title="View questionnaire"
              >
                <ProjectQuestionnaireDrawer />
              </DrawerContainer>
            </Suspense>
          }
        />
      </Routes>

      <TraningModal show={showTrainingModal} mutateUser={mutateUser}></TraningModal>

      <animated.div style={spring} className="  d-flex flex-column flex-fill">
        <div
          className="container-fluid bg-white"
          style={{
            borderBottom: '1px solid rgba(0,0,0,.1)',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="container px-lg-6 mb-3" style={{}}>
            <PageHeader
              title="Study Overview"
              body="Gain insight into the study setup and efficiently manage non-compliant subjects within the Study
                Overview. Review reasons for non-compliance, and promptly address any issues to maintain study
                compliance."
            ></PageHeader>
          </div>
        </div>
        <div className="container px-lg-6 mt-2 mb-7">
          <AttendToSubjectsList
            attendToSubjects={attendToSubjects}
            nonCompliant={nonCompliant}
            project={data}
          ></AttendToSubjectsList>

          {/*Temporary hidden - START */}
          {/*<div className="row mb-3">*/}
          {/*  <div className="col-12 mt-3 py-3">*/}
          {/*    <h6>Another thing</h6>*/}
          {/*  </div>*/}

          {/*  <div className="col-12 ">*/}
          {/*    <div*/}
          {/*      className="h-100 px-4 py-5 rounded-1  bg-gradient"*/}
          {/*      style={{*/}
          {/*        boxShadow: '0px 3px 4px -3px rgba(101, 99, 95, .4)',*/}
          {/*        //border: '1px solid rgb(35, 126, 240, .5)',*/}
          {/*        border: '1px solid rgb(164, 163, 155, .6)',*/}
          {/*        background: '#fffaf1',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <h5 style={{ color: '#5d5b58' }}>Notifications</h5>*/}
          {/*      <p className="p-small w-75 " style={{ color: '#5d5b58' }}>*/}
          {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam commodi, dignissimos et harum id iure*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*Temporary hidden - END */}

          <div className="row mb-6">
            <div className="col-12 mt-2 py-3">
              <h6>Phases</h6>
            </div>

            {protocol && protocol.phases.map((phase, index) => <ProjectPhaseListItem key={index} phase={phase} />)}
          </div>
        </div>
      </animated.div>
    </>
  )
}
