/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { Button } from 'antd'
import Alert from 'antd/lib/alert'
import { omit } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { COUNTRY_TYPE, CRUD_VIEW_TYPE, SiteType } from '../../Types'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { FormLabel } from '../../components/form/FormLabel'
import { getFormType } from '../../components/form/formFactory'
import { CLINICIAN } from '../../constants'
import { isValidEmail } from '../../util/isValidEmail'
import { populateConfig } from '../../util/populateConfig'
import { InviteTrialstaff } from './InviteTrialstaff'
import { SideBarAddSites } from './SideBarAddSites'
import { getClinician } from './clinicianConfig'

import { openNotification } from '../../components/notificationToast'
import { useClinicianById } from '../../hooks/useClinicianById'
import { useCliniciansByProjectId } from '../../hooks/useCliniciansByProjectId'
import { useConfig } from '../../hooks/useConfig'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { HideEdit } from './HideEdit'
import { displayStaffStatus } from '../../util/staffUtils'
import { ExclamationCircleOutlined } from '@ant-design/icons'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: CRUD_VIEW_TYPE
  me: any
}

const SITES_FORM_KEY = 'sites'

export const ClinicianCRUD = ({ onClose, closeDrawer, viewType = CRUD_VIEW_TYPE.CREATE, me }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }
  const { data, mutate } = useClinicianById(projectId, userId)
  const { mutate: listMutate } = useCliniciansByProjectId(projectId)
  const { data: configData } = useConfig()

  const supportedCountries: COUNTRY_TYPE[] = configData.countries

  //
  // build config object - to populate form
  //
  const configObj = viewType === CRUD_VIEW_TYPE.UPDATE ? populateConfig(getClinician(), data, viewType) : getClinician()
  const formHook = useForm()

  const { setValue, handleSubmit } = formHook

  const [errorState, setErrorState] = useState<any>()
  const [showAddSiteDrawer, setAddSiteDrawer] = useState<boolean>(false)

  const initSites = useMemo(() => {
    const defaultSiteArr = data?.sites || []
    return viewType === CRUD_VIEW_TYPE.CREATE ? [] : defaultSiteArr
  }, [viewType, data])

  const [selectedSites, setSelectedSites] = useState<SiteType[]>(initSites)

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    const a = initSites.map((site) => site._id)

    formHook.register(SITES_FORM_KEY)
    formHook.setValue(SITES_FORM_KEY, viewType === CRUD_VIEW_TYPE.VIEW ? [] : a)
  }, [formHook, viewType, initSites.length, initSites])

  const onSubmit = (formData, e) => {
    e.preventDefault()

    if (!isValidEmail(formData.email)) {
      setErrorState({ message: 'Please provide a valid email address.' })
      return
    }

    if (formData.phone.countryCode === '' || formData.phone.tel === '' || formData.phone.tel === null) {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    } else {
      setErrorState(null)
    }

    const createData = { ...formData }
    const updatedData = { ...data, ...formData }

    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'
    const sendData = viewType === CRUD_VIEW_TYPE.UPDATE ? updatedData : createData
    const omittedObj = omit(sendData, ['inviteSent'])
    const updatedSendData = { ...omittedObj, projectId: projectId }

    const path = ENDPOINTS.CLINICIANS.ACTION.clinician(projectId)

    return post(path, updatedSendData, method)
      .then((response) => {
        console.log('response >>> ', response)
        const existingUser: boolean = response.data.existingUser

        const title =
          viewType === CRUD_VIEW_TYPE.UPDATE
            ? 'Update clinician'
            : existingUser
            ? 'The user has been successfully added.'
            : 'The user has been successfully created.'
        const msg =
          viewType === CRUD_VIEW_TYPE.UPDATE
            ? 'Clinician has been successfully updated'
            : existingUser
            ? 'You can now re-invite them by clicking on ‘Reset password and resend invite’ button'
            : 'You can now invite them by clicking on ‘Send Invite’ button'

        openNotification(title, msg, 'happy')

        mutate()
        listMutate()

        handleClose()
      })
      .catch((error) => {
        setErrorState(error)
      })
  }

  const updateSelectedSites = (theSites) => {
    const arrOfIds = theSites.map((item, index) => {
      return item._id
    })

    setValue(SITES_FORM_KEY, arrOfIds)
    setSelectedSites(theSites)
  }

  // const removeSelectedSite = (id) => {
  //   const cur = getValues(SITES_FORM_KEY)
  //
  //   const updated = cur.filter((item) => {
  //     return item !== id
  //   })
  //
  //   const updatedSites: SiteType[] = selectedSites.filter((item) => {
  //     return item._id !== id
  //   })
  //
  //   setValue(SITES_FORM_KEY, updated)
  //   setSelectedSites(updatedSites)
  // }

  // const open = () => {
  //   setAddSiteDrawer(true)
  // }

  const closeAddSiteDrawer = () => {
    setAddSiteDrawer(false)
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  return (
    <>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {configObj.map((item, index) => {
              const comp = getFormType(item, index, formHook, viewType, supportedCountries)
              return comp
            })}
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          {/* <div className="col-12 mb-4">
            <FormLabel label="Assign sites"></FormLabel>
            <SiteBarSiteList
              open={open}
              selectedSites={selectedSites}
              removeSelectedSite={removeSelectedSite}
              viewType={CRUD_VIEW_TYPE.CREATE}
            ></SiteBarSiteList>
          </div> */}

          <div className="col-12">
            <div
              className="h-100 px-4 py-2 rounded-1  bg-gradient"
              style={{
                boxShadow: '0px 3px 4px -3px rgba(101, 99, 95, .4)',
                border: '1px solid rgb(164, 163, 155, .6)',
                background: '#fffaf1',
              }}
            >
              <div className="row">
                <div className="col-1 d-flex justify-content-center">
                  <ExclamationCircleOutlined style={{ scale: '150%', color: 'rgba(164, 163, 155, 1)' }} />
                </div>
                <div className="col-11">
                  <p className="p-small" style={{ color: '#5d5b58' }}>
                    <b>Mobile phone number</b> is needed for login to the Portal. All site staff receive a multi-factor
                    authentication code by SMS when logging in.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === CRUD_VIEW_TYPE.CREATE ? 'Create user' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <div className="d-flex ">
              <FormLabel label="Name"></FormLabel>
              <p className="ms-2 p-small opacity-25 ">{data ? data._id : '-'}</p>
            </div>
            <h6 className="fw-bold">{data ? data.name : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Email"></FormLabel>
            <h6 className="fw-bold text-truncate">{data ? data.email : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phone"></FormLabel>
            <h6 className="fw-bold">{data ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Created by"></FormLabel>
            <h6 className="fw-bold text-truncate">{data ? data.createdBy : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Job title"></FormLabel>
            <h6 className="fw-bold">{data ? data.jobTitle : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{displayStaffStatus(data)}</h6>
          </div>

          <div className="col-12 d-flex justify-content-end">
            <HideEdit userType={me.type} />
          </div>

          <div className="col-12 mt-2">
            <InviteTrialstaff
              data={data}
              mutate={mutate}
              userId={userId}
              userType={CLINICIAN}
              me={me}
              handleClose={handleClose}
            ></InviteTrialstaff>
          </div>
        </div>
      )}
      {showAddSiteDrawer ? (
        <DrawerContainer setVisible={closeAddSiteDrawer} projectId={''} width={550} to="." title="Site list">
          <SideBarAddSites projectId={projectId} setSelectedSites={updateSelectedSites} selectedSites={selectedSites} />
        </DrawerContainer>
      ) : (
        <></>
      )}
    </>
  )
}
