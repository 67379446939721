/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { useParams } from 'react-router-dom'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import { omit } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { mutate as mutateGlobal } from 'swr'
import { COUNTRY_TYPE, CRUD_VIEW_TYPE, SiteType } from '../../Types'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { FormLabel } from '../../components/form/FormLabel'
import { JustificationInput } from '../../components/form/JustificationInput'
import { getFormType } from '../../components/form/formFactory'
import { openNotification } from '../../components/notificationToast'
import { TRIAL_STAFF } from '../../constants'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useTrialStaffById } from '../../hooks/useTrialStaffById'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { isValidEmail } from '../../util/isValidEmail'
import { populateConfig } from '../../util/populateConfig'
import { displayStaffStatus } from '../../util/staffUtils'
import { HideEdit } from './HideEdit'
import { InviteTrialstaff } from './InviteTrialstaff'
import { SideBarAddSites } from './SideBarAddSites'
import { SiteBarSiteList } from './SideBarSiteList'
import { getTrialStaff } from './trialStaffConfig'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: CRUD_VIEW_TYPE
  me: any
}

const SITES_FORM_KEY = 'sites'

export const TrialStaffCRUD = ({ onClose, closeDrawer, viewType = CRUD_VIEW_TYPE.CREATE, me }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const { data, mutate } = useTrialStaffById(projectId, userId)

  const { data: configData } = useByEndpoint(ENDPOINTS.PROJECTS.CONFIG)
  const supportedCountries: COUNTRY_TYPE[] = configData.countries

  //
  // build config object - to populate form
  //

  const configObj =
    viewType === CRUD_VIEW_TYPE.UPDATE ? populateConfig(getTrialStaff(), data, viewType) : getTrialStaff()

  const formHook = useForm()

  const { handleSubmit, getValues, setValue } = formHook
  const [errorState, setErrorState] = useState<any>()

  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  /*  const selectedSiteIds = getValues(SITES_FORM_KEY) || []

  const selectedSites =
    viewType === CRUD_VIEW_TYPE.VIEW
      ? data.sites.filter((site) => {
          return selectedSiteIds.indexOf(site._id)
        })
      : []*/

  const initSites = useMemo(() => {
    const defaultSiteArr = data?.sites || []
    return viewType === CRUD_VIEW_TYPE.CREATE ? [] : defaultSiteArr
  }, [viewType, data?.sites])

  const [selectedSites, setSelectedSites] = useState<SiteType[]>(initSites)

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }

    if (closeDrawer) {
      closeDrawer(false)
      setShowDrawer(false)
    }
  }, [onClose, closeDrawer])

  useEffect(() => {
    setSelectedSites(initSites)

    const a = initSites.map((site) => site._id)

    formHook.register(SITES_FORM_KEY)
    formHook.setValue(SITES_FORM_KEY, viewType === CRUD_VIEW_TYPE.VIEW ? [] : a)
  }, [formHook, viewType, initSites])

  const onSubmit = (formData, e) => {
    e.preventDefault()

    console.log('TrialStaffCRUD > formDa = ', formData)

    if (!isValidEmail(formData.email)) {
      setErrorState({ message: 'Please provide a valid email address.' })
      return
    }

    if (formData.phone.countryCode === '' || formData.phone.tel === '' || formData.phone.tel === null) {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    } else {
      setErrorState(null)
    }

    const createData = { ...formData, projectId: projectId }
    const updatedData = { ...data, ...formData }

    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'
    const sendData = viewType === CRUD_VIEW_TYPE.UPDATE ? updatedData : createData

    const omittedObj = omit(sendData, ['inviteSent'])

    const updatedSendData = { ...omittedObj }

    const CREATE_URL = ENDPOINTS.TRIAL_STAFF.ACTION.create(projectId) //PORTAL_API_URL + `/projects/${projectId}/trialStaff/action`

    return post(CREATE_URL, updatedSendData, method)
      .then(() => {
        const title = viewType === CRUD_VIEW_TYPE.UPDATE ? 'Update trial staff' : 'Create trial staff'
        const msg =
          viewType === CRUD_VIEW_TYPE.UPDATE
            ? 'Trial staff has been successfully updated'
            : 'Trial staff has been successfully created'
        openNotification(title, msg, 'happy')
        mutate()
        mutateGlobal(ENDPOINTS.CTM.byProjectId(projectId))
        mutateGlobal(ENDPOINTS.DM.byProjectId(projectId))
        mutateGlobal(ENDPOINTS.CRA.byProjectId(projectId))

        handleClose()
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  /*const setSelectedSites = (arrOfIds) => {
    setValue(SITES_FORM_KEY, arrOfIds)
  }*/

  const updateSelectedSites = (theSites) => {
    const arrOfIds = theSites.map((item, index) => {
      return item._id
    })

    setValue(SITES_FORM_KEY, arrOfIds)
    setSelectedSites(theSites)
  }

  const removeSelectedSite = (id) => {
    const cur = getValues(SITES_FORM_KEY)

    const updated = cur.filter((item) => {
      return item !== id
    })

    const updatedSites: SiteType[] = selectedSites.filter((item) => {
      return item._id !== id
    })

    setValue(SITES_FORM_KEY, updated)
    setSelectedSites(updatedSites)
  }

  /*  const removeSelectedSite = (id) => {
    const cur = getValues(SITES_FORM_KEY)
    const updated = cur.filter((item) => {
      return item !== id
    })

    setValue(SITES_FORM_KEY, updated)
  }*/

  const open = () => {
    setShowDrawer(true)
  }

  const closeD = () => {
    setShowDrawer(false)
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {configObj.map((item, index) => {
              const comp = getFormType(item, index, formHook, viewType, supportedCountries)
              return comp
            })}
          </div>

          <div className="col-12 mt-3 mb-2">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          {data && data.type !== 'CTM' && data.type !== 'DM' ? (
            <div className="col-12 mb-4">
              <SiteBarSiteList
                open={open}
                selectedSites={selectedSites}
                removeSelectedSite={removeSelectedSite}
                viewType={CRUD_VIEW_TYPE.CREATE}
              ></SiteBarSiteList>
            </div>
          ) : (
            <></>
          )}

          {/* <div className="col-12 mb-4">
            <FormLabel label="Assign sites"></FormLabel>
            <SiteBarSiteList
              open={open}
              selectedSites={selectedSites}
              removeSelectedSite={removeSelectedSite}
            ></SiteBarSiteList>
          </div> */}

          <div className="col-12">
            <div
              className="h-100 px-4 py-2 rounded-1  bg-gradient"
              style={{
                boxShadow: '0px 3px 4px -3px rgba(101, 99, 95, .4)',
                border: '1px solid rgb(164, 163, 155, .6)',
                background: '#fffaf1',
              }}
            >
              <div className="row">
                <div className="col-1 d-flex justify-content-center">
                  <ExclamationCircleOutlined style={{ scale: '150%', color: 'rgba(164, 163, 155, 1)' }} />
                </div>
                <div className="col-11">
                  <p className="p-small" style={{ color: '#5d5b58' }}>
                    <b>Mobile phone number</b> is needed for login to the Portal. All site staff receive a multi-factor
                    authentication code by SMS when logging in.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*Justification*/}
          <div className="mt-2 mb-2">
            {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === CRUD_VIEW_TYPE.CREATE ? 'Create staff' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <FormLabel label="Role"></FormLabel>
            <h6 className="fw-bold">{data ? data.type : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Name"></FormLabel>
            <h6 className="fw-bold">{data ? data.name : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Email"></FormLabel>
            <h6 className="fw-bold">{data ? data.email : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phone"></FormLabel>
            <h6 className="fw-bold">{data ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Created by"></FormLabel>
            <h6 className="fw-bold">{data ? data.createdBy : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{displayStaffStatus(data)}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Job title"></FormLabel>
            <h6 className="fw-bold">{data ? data.jobTitle : '-'}</h6>
          </div>

          {data && data.type !== 'CTM' && data.type !== 'DM' ? (
            <div className="col-12 mb-4">
              <SiteBarSiteList selectedSites={selectedSites}></SiteBarSiteList>
            </div>
          ) : (
            <></>
          )}

          <div className="col-12 d-flex justify-content-end">
            <HideEdit userType={me.type} staffType={data.type} />
          </div>

          <div className="col-12 d-flex justify-content-between align-items-center  mt-2">
            <InviteTrialstaff
              data={data}
              mutate={mutate}
              userId={userId}
              userType={TRIAL_STAFF}
              me={me}
              handleClose={handleClose}
            ></InviteTrialstaff>
          </div>
        </div>
      )}

      {showDrawer ? (
        <DrawerContainer setVisible={closeD} projectId={''} width="30%" to="." title="Add sites to trial staff">
          <SideBarAddSites projectId={projectId} setSelectedSites={updateSelectedSites} selectedSites={selectedSites} />
        </DrawerContainer>
      ) : (
        <></>
      )}
    </>
  )
}
