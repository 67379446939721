/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import React from 'react'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../../components/display/PageHeader'
import { useFadeIn } from '../../components/animation/useFadeIn'
import { useMeSites } from '../../hooks/useMeSites'
import { SiteListRender } from './SiteListRender'
import { Empty } from 'antd'

export const SiteListPage = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useMeSites(projectId)

  const { animated, spring } = useFadeIn()

  return (
    <animated.div style={spring} className="d-flex flex-column flex-fill">
      <div
        className="container-fluid bg-white "
        style={{
          borderBottom: '1px solid rgba(0,0,0,.1)',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
        }}
      >
        <div className="container mb-3 px-lg-6">
          <PageHeader
            title="Site list"
            body="View and access details for the list of participating study sites, including names and addresses. For any required updates, please reach out to Studies&Me personnel, to ensure accurate and up-to-date information."
          ></PageHeader>
        </div>
      </div>

      <div className="container px-lg-6 mt-4" style={{}}>
        <div className="row" style={{}}>
          {data.length > 0 ? (
            <SiteListRender data={data}></SiteListRender>
          ) : (
            <div className="mt-2">
              <Empty description="There are no active sites associated with the study" />
            </div>
          )}
        </div>
      </div>
    </animated.div>
  )
}
