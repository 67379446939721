/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/09/2023.
 */
import { useParams } from 'react-router-dom'
import { UserTypes } from '../../Types'
import { useActionActivity } from '../../hooks/useActionActivity'
import { AuditFactory } from '../../util/auditFactory'

type Props = { type: UserTypes }

export const ActionActivity = ({ type }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const { data } = useActionActivity(projectId, userId, type)

  return (
    <div className="container-fluid ">
      <AuditFactory data={data}></AuditFactory>
    </div>
  )
}
