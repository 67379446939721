/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/05/2023.
 */
import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as BackArrow } from '../../assets/backArrow.svg'

type Props = {
  title?: React.ReactElement | string
  children?: React.ReactElement | string | null
  backTo?: string
  body?: string
}

export const PageHeader = ({ title = 'Page title', body = '', children, backTo }: Props) => {
  return (
    <div className="row " style={{}}>
      <div className="col-12 py-4 d-flex justify-content-between mt-3">
        <div className="w-100">
          {backTo ? (
            <Link to={backTo} className="d-flex mb-1 text-decoration-none">
              <BackArrow className="mt-1"></BackArrow>
              <p className="p-small ms-1">Back</p>
            </Link>
          ) : (
            <div className="d-flex mb-1 "></div>
          )}

          <h2 className="mb-3">{title}</h2>
          {body ? (
            <p className="text-muted " style={{ maxWidth: '80%', fontSize: '.875rem' }}>
              {body}
            </p>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  )
}
