import { Button, Steps } from 'antd'
import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { SUBJECT_STATUS_ENUM } from '../../../Types'
import { useByEndpoint } from '../../../hooks/useByEndpoint'
import { useById } from '../../../hooks/useById'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { CompleteModal } from './CompleteModal'
import { DisqualifyModal } from './DisqualifyModal'
import { MoveModal } from './MoveModal'
import { MovePreviousModal } from './MovePreviousModal'
import { ReQualifyModal } from './RequalifyModal'
import { UnCompleteModal } from './UnCompleteModal'
import { format } from 'date-fns'

const buttonStyle: React.CSSProperties = {
  textAlign: 'start',
  width: '100%',
  borderRadius: '4px',
}

//
// TODO: All the modal types can be combined into one to avoid having 1k lines of codes, will be big rewrite
//
export const SubjectUpdateWrapper = () => {
  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }

  const { data: protocolData } = useByEndpoint(ENDPOINTS.PROTOCOLS.byProjectId(projectId))

  const phasesData = protocolData.phases

  const allSubjectsEndpoint = ENDPOINTS.SUBJECTS.get(projectId)
  const { data: subjectData, mutate: mutateSubjectData } = useById(allSubjectsEndpoint, subjectId)

  // Get the order of the current phase
  const currentPhaseOrder = subjectData.currentPhase.order
  // nextPhase must contain the next phase with the smallest order greater than the current phase
  const nextPhase = phasesData
    // Filter the phases to include only those with a different ID and a higher order than the current phase
    .filter((phase) => phase._id !== subjectData.stableId && phase.order > currentPhaseOrder)
    // Reduce to find the phase with the smallest order among the filtered phases
    .reduce((minPhase, phase) => (phase.order < minPhase.order ? phase : minPhase), {
      // minPhase has the first phase from the filtered array
      ...phasesData.find((phase) => phase._id !== subjectData.stableId && phase.order > currentPhaseOrder),
    })

  const previousPhase = phasesData
    // Filter the phases to include only those with a different ID and a lower order than the current phase
    .filter((phase) => phase._id !== subjectData.stableId && phase.order < currentPhaseOrder)
    // Reduce to find the phase with the largest order among the filtered phases
    .reduce((maxPhase, phase) => (phase.order > maxPhase.order ? phase : maxPhase), {
      // maxPhase has the first phase from the filtered array
      ...phasesData.find((phase) => phase._id !== subjectData.stableId && phase.order < currentPhaseOrder),
    })

  const [isDisqualifyModalOpen, setIsDisqualifyModalOpen] = useState(false)
  const [isReQualifyModalOpen, setIsReQualifyModalOpen] = useState(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [isMovePreviousModalOpen, setIsMovePreviousModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isUnCompleteModalOpen, setIsUnCompleteModalOpen] = useState(false)

  const showDisqualifyModal = () => {
    setIsDisqualifyModalOpen(true)
  }

  const showReQualifyModal = () => {
    setIsReQualifyModalOpen(true)
  }

  const showMoveModal = () => {
    setIsMoveModalOpen(true)
  }

  const showMovePreviousModal = () => {
    setIsMovePreviousModalOpen(true)
  }

  const showCompleteModal = () => {
    setIsCompleteModalOpen(true)
  }

  const showUnCompleteModal = () => {
    setIsUnCompleteModalOpen(true)
  }

  const getCurrentStep = (phasesData, subjectData) => {
    const phaseIds = phasesData.map((phase) => phase._id)
    const currentPhaseId = subjectData.currentPhase._id

    const onboardingStep = 0
    const completedStep = phaseIds.length + 1 // Last step

    // If the current phase ID matches the onboarding phase ID, return onboardingStep
    if (currentPhaseId === onboardingStep) {
      return onboardingStep
    }

    // Find the index of the currentPhaseId in the phaseIds array
    const currentStep = phaseIds.indexOf(currentPhaseId) + 1

    // If the current phase ID is not found in phaseIds, use completedStep
    if (currentStep === -1) {
      return completedStep
    }

    // Otherwise, return the currentStep for a regular phase
    return currentStep
  }

  const buildSteps = (phasesData, subjectData) => {
    const setupDate = format(new Date(subjectData.createdAt), 'd MMM yyyy')

    const onboardingStep = { title: 'Initiation', description: setupDate }

    const phasesSteps = phasesData.map((phase) => {
      return { title: phase.title, description: 'For ' + phase.duration + ' days' }
    })

    const completedStep = { title: 'Completed', description: '' }

    return [onboardingStep, ...phasesSteps, completedStep]
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h6 className="font-weight-bold mb-3">Subject Journey</h6>
          <p className="p-small opacity-75 p-1 mb-3">
            Please press the action button below to update the subject's status and advance them in the study. Following
            randomization, you will be required to select the target body part for the subject.
          </p>
          <Steps
            progressDot
            className="mb-2"
            direction="vertical"
            status={subjectData.status === SUBJECT_STATUS_ENUM.TERMINATED ? 'error' : 'process'}
            current={
              subjectData.status === SUBJECT_STATUS_ENUM.COMPLETED
                ? getCurrentStep(phasesData, subjectData) + 1
                : getCurrentStep(phasesData, subjectData)
            }
            items={buildSteps(phasesData, subjectData)}
          />

          {Object.keys(nextPhase).length !== 0 ? (
            <div className="row">
              <div className="col-12">
                <Button
                  type="primary"
                  style={buttonStyle}
                  onClick={showMoveModal}
                  disabled={
                    !(
                      subjectData.status === SUBJECT_STATUS_ENUM.ACTIVE &&
                      (subjectData.onboard !== 'IS_SETUP' || subjectData.board !== 'ONBOARDED_COMPLETED')
                    )
                  }
                >
                  Move to Next Phase
                </Button>
                <div className="mt-2">
                  <p className="p-small opacity-75">
                    Please note: A subject can only be moved if their status is ACTIVE and they have an assigned device.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {subjectData.status === SUBJECT_STATUS_ENUM.COMPLETED ? (
                <Button type="primary" style={buttonStyle} onClick={showUnCompleteModal}>
                  Revert Study Completion
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={buttonStyle}
                  disabled={subjectData.status !== SUBJECT_STATUS_ENUM.ACTIVE}
                  onClick={showCompleteModal}
                >
                  Study Completion
                </Button>
              )}
            </>
          )}
          <hr></hr>
          {/* ------------------------------------------------------------------------------- */
          /* Should be able to move to previous phase ONLY if not in order = 1  (first phase) */
          /* -------------------------------------------------------------------------------- */}
          <div className="mt-3">
            <Button
              type="primary"
              style={buttonStyle}
              onClick={showMovePreviousModal}
              disabled={
                currentPhaseOrder === 1 ||
                subjectData.status === SUBJECT_STATUS_ENUM.TERMINATED ||
                subjectData.status === SUBJECT_STATUS_ENUM.COMPLETED
              }
            >
              Move to Previous Phase
            </Button>
          </div>

          <hr></hr>

          <div className="">
            <Button
              type="primary"
              danger
              style={buttonStyle}
              onClick={showDisqualifyModal}
              disabled={subjectData.status !== SUBJECT_STATUS_ENUM.ACTIVE}
            >
              Study Termination
            </Button>
            <hr></hr>
            <div className="mt-2">
              <Button
                style={buttonStyle}
                onClick={showReQualifyModal}
                disabled={subjectData.status !== SUBJECT_STATUS_ENUM.TERMINATED}
              >
                Reactivate Subject
              </Button>
            </div>
          </div>
        </div>
      </div>

      <DisqualifyModal
        isModalOpen={isDisqualifyModalOpen}
        setIsDisqualifyModalOpen={setIsDisqualifyModalOpen}
        subjectId={subjectData._id}
        mutateSubjectData={mutateSubjectData}
      ></DisqualifyModal>

      <ReQualifyModal
        isModalOpen={isReQualifyModalOpen}
        setIsReQualifyModalOpen={setIsReQualifyModalOpen}
        subjectId={subjectData._id}
        mutateSubjectData={mutateSubjectData}
      ></ReQualifyModal>

      <MoveModal
        isModalOpen={isMoveModalOpen}
        setIsMoveModalOpen={setIsMoveModalOpen}
        subjectData={subjectData}
        mutateSubjectData={mutateSubjectData}
        nextPhase={nextPhase}
      ></MoveModal>

      <MovePreviousModal
        isModalOpen={isMovePreviousModalOpen}
        setIsMovePreviousModalOpen={setIsMovePreviousModalOpen}
        subjectData={subjectData}
        mutateSubjectData={mutateSubjectData}
        previousPhase={previousPhase}
      ></MovePreviousModal>

      <CompleteModal
        isModalOpen={isCompleteModalOpen}
        setIsReQualifyModalOpen={setIsCompleteModalOpen}
        subjectId={subjectData._id}
        mutateSubjectData={mutateSubjectData}
      ></CompleteModal>

      <UnCompleteModal
        isModalOpen={isUnCompleteModalOpen}
        setIsUnCompleteModalOpen={setIsUnCompleteModalOpen}
        subject={subjectData}
        mutateSubjectData={mutateSubjectData}
      ></UnCompleteModal>
    </>
  )
}
