/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/05/2023.
 */
import { SiteType } from '../../Types'
import { Empty, Popconfirm } from 'antd'
import Button from 'antd/lib/button'
import { CloseCircleFilled } from '@ant-design/icons'

type SideBarSiteListProps = {
  selectedSites: SiteType[]
  open?: () => void
  removeSelectedSite?: (id: string) => void
  viewType?: 'CREATE' | 'UPDATE' | 'VIEW'
}
export const SiteBarSiteList = ({
  selectedSites,
  open,
  removeSelectedSite,
  viewType = 'VIEW',
}: SideBarSiteListProps) => {
  return (
    <div className="border rounded-3 ">
      {selectedSites.length === 0 ? (
        <div onClick={open} className="py-5 pointer">
          <Empty description="No sites have been assigned to the user."></Empty>
          <div className="d-flex justify-content-center mt-3">
            {open ? (
              <Button onClick={open} type="primary" className="me-2">
                Add site
              </Button>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <div className="border-bottom py-3 d-flex justify-content-between align-items-center">
            <p className="ms-3">
              Click the <span className="fw-bold">Add site</span> button to select a site for the user.
            </p>
            {open ? (
              <Button onClick={open} type="primary" className="me-3">
                Add site
              </Button>
            ) : null}
          </div>

          <div className="">
            {selectedSites.map((item, index) => {
              return <SiteItem key={index} site={item} remove={removeSelectedSite}></SiteItem>
            })}
          </div>
        </>
      )}
    </div>
  )
}

type SiteItemProps = { site: SiteType; remove?: (id: string) => void }
const SiteItem = ({ site, remove }: SiteItemProps) => {
  const cancel = () => {}
  const confirm = () => {
    if (remove) {
      remove(site._id)
    }
  }

  return (
    <div className="d-flex justify-content-between py-3 border-bottom">
      <div className="ps-3">
        <h6 className="mb-0">
          {site.title} - {site.siteNumber}
        </h6>
      </div>

      <div className="me-4 pointer">
        <Popconfirm
          placement="left"
          title="Remove access"
          description="Are you sure to remove access to this site?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          {remove ? (
            <div className="d-flex noselect">
              <p className="me-3">Remove access</p>
              <CloseCircleFilled style={{ fontSize: '1.5rem' }} />{' '}
            </div>
          ) : null}
        </Popconfirm>
      </div>
    </div>
  )
}
