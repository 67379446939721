import { SiteType } from '../../Types'
import { Checkbox } from 'antd'
import React from 'react'

type SideBarSiteListItemType = {
  selectSiteClick?: (site) => void
  site: SiteType
  isChecked: boolean
}

export const SideBarSiteListItem = ({ site, selectSiteClick, isChecked }: SideBarSiteListItemType) => {
  const clickker = () => {
    if (selectSiteClick) {
      selectSiteClick(site._id)
    } else {
      console.log('SideBarAddSites > clicl = ', site._id)
    }
  }

  return (
    <div className="col-12 d-flex  align-items-center border-bottom">
      <div onClick={clickker} className="pointer ps-1 h-50 pe-3 " style={{ borderRight: '1px solid rgba(0,0,0,.2)' }}>
        <Checkbox className="mt-2 " checked={isChecked} value={site._id}></Checkbox>
      </div>

      <div className="w-100 ms-3 py-3 noselect">
        <div className="d-flex align-items-center">
          <div className="w-75">
            <p className="p-mini opacity-75 ">Title</p>
            <h6 className="fw-bold">{site.title}</h6>
          </div>
          <div className="pb-2">
            <p className="p-mini opacity-75">Site Id</p>
            <p className="fw-bold">{site.siteNumber}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
