import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useProjects = () => {
  const path = ENDPOINTS.PROJECTS.PROJECTS
  const { data, error, mutate } = useSWR(path, getReq, { suspense: true })

  //
  // Notice that this out puts an object which has a
  // {
  //    ...
  //    projects: only a single project. The projects array is overridden for the clinician
  //    ...
  // }
  //
  //

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
