/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import { Button } from 'antd'
import { Suspense, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { CRUD_VIEW_TYPE, PROJECT_STATES, USER_TYPES } from '../../Types'
import { useFadeIn } from '../../components/animation/useFadeIn'
import { PageHeader } from '../../components/display/PageHeader'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { SubpageNavigator } from '../../components/navigator/SubpageNavigator'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useMe } from '../../hooks/useMe'
import { SiteCRUD } from '../site/SiteCRUD'
import { ActionActivity } from './ActionActivity'
import { ClinicianCRUD } from './ClinicianCRUD'
import { ClinicianPii } from './ClinicianPii'
import { ClinicianView } from './ClinicianView'
import { CraView } from './CraView'
import { CtmView } from './CtmView'
import { DmView } from './DmView'
import { LoginActivity } from './LoginActivity'
import { TrialStaffCRUD } from './TrialStaffCRUD'
import { TrialStaffPii } from './TrialStaffPii'
import { useProjectById } from '../../hooks/useProjectById'
import { ClinicianSiteCRUD } from './ClinicianSiteCRUD'
import { TrialStaffSiteCRUD } from './TrialStaffSiteCRUD'

const CREATE_CLINICIAN = 'create-clinician'
const CREATE_TRAIL_STAFF = 'create-staff'

export const TrailStaffPage = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data: me } = useMe(projectId)
  const { data: projectData } = useProjectById(projectId)

  const subpageMenu = ['Clinician', 'CRA', 'DM', 'CTM']
  const [state, setState] = useState<number>(0)
  const { animated, spring } = useFadeIn()

  const getPageIndex = (index) => {
    setState(index)
  }

  const closeDrawer = (value) => {
    // Implement if needed to update page (with mutate)
    // if (!value) {
    //   mutate()
    // }
  }

  return (
    <>
      <Routes>
        <Route
          path={'/sites/:siteId/*'}
          element={
            <DrawerContainer projectId={projectId} to="../" title="Site information">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <SiteCRUD></SiteCRUD>
              </Suspense>
            </DrawerContainer>
          }
        />
        <Route
          path={'/create-staff'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer projectId={projectId} setVisible={closeDrawer} to="../" title="Create new trial staff">
                <TrialStaffCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE} me={me}></TrialStaffCRUD>
              </DrawerContainer>
            </Suspense>
          }
        />

        <Route
          path="/view-staff/:userId/*"
          element={
            <>
              <DrawerContainer
                projectId={projectId}
                setVisible={closeDrawer}
                to="../"
                title="Trial staff information"
                header={
                  <div className="">
                    <Link to={'logins'} className="">
                      <Button type="default" className="me-2">
                        Logins
                      </Button>
                    </Link>
                    <Link to={'actions'} className="">
                      <Button type="default" className="me-2">
                        Actions
                      </Button>
                    </Link>
                  </div>
                }
              >
                <TrialStaffCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW} me={me}></TrialStaffCRUD>
              </DrawerContainer>

              <Routes>
                <Route
                  path="/sites"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Update Trial Staff site access">
                      <TrialStaffSiteCRUD
                        closeDrawer={closeDrawer}
                        viewType={CRUD_VIEW_TYPE.UPDATE}
                      ></TrialStaffSiteCRUD>
                    </DrawerContainer>
                  }
                />
              </Routes>

              <Routes>
                <Route
                  path="/edit"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Update Trial Staff information">
                      <TrialStaffPii />
                    </DrawerContainer>
                  }
                />
              </Routes>

              <Routes>
                <Route
                  path="/logins"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Logins">
                      <LoginActivity type={USER_TYPES.TRIAL_STAFF}></LoginActivity>
                    </DrawerContainer>
                  }
                />
                <Route
                  path="/actions"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Actions">
                      <ActionActivity type={USER_TYPES.TRIAL_STAFF}></ActionActivity>
                    </DrawerContainer>
                  }
                />
              </Routes>
            </>
          }
        />

        <Route
          path={'/clinician/:userId/*'}
          element={
            <>
              <DrawerContainer
                projectId={''}
                to="../"
                title="View clinician"
                header={
                  <div className="">
                    <Link to={'logins'} className="">
                      <Button type="default" className="me-2">
                        Logins
                      </Button>
                    </Link>
                    <Link to={'actions'} className="">
                      <Button type="default" className="me-2">
                        Actions
                      </Button>
                    </Link>
                  </div>
                }
              >
                <ClinicianCRUD viewType={CRUD_VIEW_TYPE.VIEW} me={me}></ClinicianCRUD>
              </DrawerContainer>

              <Routes>
                <Route
                  path="/edit/*"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Update personal information">
                      <ClinicianPii></ClinicianPii>
                    </DrawerContainer>
                  }
                />
                <Route
                  path="/logins"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Logins">
                      <LoginActivity type={USER_TYPES.CLINICIAN}></LoginActivity>
                    </DrawerContainer>
                  }
                />
                <Route
                  path="/actions"
                  element={
                    <DrawerContainer projectId={''} to="../" title="Actions">
                      <ActionActivity type={USER_TYPES.CLINICIAN}></ActionActivity>
                    </DrawerContainer>
                  }
                />
              </Routes>
            </>
          }
        />

        <Route
          path={'/clinician/:userId/edit-sites/*'}
          element={
            <>
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <DrawerContainer projectId={''} to="../" title="Site access" setVisible={closeDrawer}>
                  <ClinicianSiteCRUD viewType={CRUD_VIEW_TYPE.UPDATE} me={me}></ClinicianSiteCRUD>
                </DrawerContainer>
              </Suspense>
            </>
          }
        />

        <Route
          path={'/' + CREATE_CLINICIAN}
          element={
            <DrawerContainer projectId={''} to="../" title="Create clinician">
              <ClinicianCRUD viewType={CRUD_VIEW_TYPE.CREATE} me={me}></ClinicianCRUD>
            </DrawerContainer>
          }
        />
      </Routes>

      <animated.div style={spring} className=" d-flex flex-column flex-fill">
        <div className="container-fluid bg-white ">
          <div className="container px-lg-6">
            <PageHeader
              title="Trial staff"
              body={
                me.type === 'CTM'
                  ? 'Create and manage Clinicians and Trial Staff participating in the study. Review individual profiles to view titles, roles, and other information. Review the audit log and logins for a detailed history of system activities, ensuring transparency and security.'
                  : 'Get a complete overview of the Trial Staff participating in the study. Explore individual profiles to view titles, roles, and other information. Review the audit log and logins for a detailed history of system activities, ensuring transparency and security.'
              }
            >
              {me.type === 'CTM' ? (
                <div className="d-flex align-items-center">
                  <Link to={state !== 0 ? CREATE_TRAIL_STAFF : CREATE_CLINICIAN}>
                    <Button type="primary" disabled={projectData.status === PROJECT_STATES.ARCHIVED}>
                      Create {state === 0 ? 'Clinician' : 'Trial Staff'}
                    </Button>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </PageHeader>
          </div>
        </div>

        <SubpageNavigator menu={subpageMenu} getPageIndex={getPageIndex}>
          <Suspense fallback={<SpinnerFullHeight />}>
            <ClinicianView></ClinicianView>
          </Suspense>
          <Suspense fallback={<SpinnerFullHeight />}>
            <CraView></CraView>
          </Suspense>
          <Suspense fallback={<SpinnerFullHeight />}>
            <DmView></DmView>
          </Suspense>
          <Suspense fallback={<SpinnerFullHeight />}>
            <CtmView></CtmView>
          </Suspense>
        </SubpageNavigator>

        {/*      <div className="row" style={{}}>
        <div className="col-12">dasdsad</div>
      </div>*/}
      </animated.div>
    </>
  )
}
