/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/11/2023.
 */
/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/09/2022.
 */
import React, { useState } from 'react'
import { Button, Checkbox, Modal } from 'antd'

import { useParams } from 'react-router-dom'
import { post } from '../service/API'
import { ENDPOINTS } from '../service/ENDPOINTS'

type Props = { show: boolean; mutateUser: () => void }

export const TraningModal = ({ show, mutateUser }: Props) => {
  const [hasCompleted, setHasCompledted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { projectId } = useParams() as { projectId: string }

  const confirm = () => {
    setLoading(true)
    console.log('TrainingModal > send = ')
    const p = ENDPOINTS.PROJECTS.TRAINING.complete(projectId)
    post(p, { completed: true, projectId: projectId })
      .then((result) => {
        console.log('TrainingModal > result = ', result)
        setLoading(false)
        mutateUser()
        return result
      })
      .catch((error) => {
        alert(error)
        return Promise.reject(error)
      })
  }

  const onChange = () => {
    setHasCompledted(!hasCompleted)
  }

  return (
    <Modal
      title=""
      centered
      closable={false}
      open={show}
      onOk={confirm}
      width={'60%'}
      footer={[
        <Button key="submit" type="primary" onClick={confirm} disabled={!hasCompleted} loading={loading}>
          Submit
        </Button>,
      ]}
    >
      <div className="container-fluid px-0">
        <div className="row" style={{}}>
          <div className="col-12 mt-2 mb-4">
            <h2>Have you completed your training? </h2>
            <p className="mt-2">Please confirm that you have completed the site tool training, before you proceed.</p>
            <div className="d-flex mt-3">
              <Checkbox checked={hasCompleted} onChange={onChange}>
                <p className="">Yes, I have completed the training</p>
              </Checkbox>
            </div>
            <p className="mt-3 ">If you haven't completed the training yet, please reach out to your CRA</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
