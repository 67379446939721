/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 22/11/2023.
 */

type Props = {
  className?: string
  status: string
}

const getColor = (status) => {
  if (status === 'ACTIVE') {
    return { bgColor: '#d2f5d1', borderColor: '#2ab01a', fontColor: '#249118' }
  }

  if (status === 'TERMINATED') {
    return { bgColor: '#f8dcdc', borderColor: '#e19999', fontColor: '#c92323' }
  }
  return { bgColor: '#E6F4FF', borderColor: '#1c5dce', fontColor: '#1c5dce' }
}

export const StatusTag = ({ className = '', status }: Props) => {
  const { bgColor, borderColor, fontColor } = getColor(status)

  return (
    <div
      className={'rounded-2 py-1 d-inline-block px-2 ' + className}
      style={{ background: bgColor, border: `1px solid ${borderColor}` }}
    >
      <p className="p-mini" style={{ color: fontColor }}>
        {status}
      </p>
    </div>
  )
}
