import { create } from 'zustand'
import { Auth } from 'aws-amplify'

export type UserType = {
  challengeName?: string
  challengeParam?: { requiredAttributes: string[] }
  attributes: { email: string; sub: string; name: string; 'custom:userType': string }
} | null

export interface AuthStoreType {
  user: UserType
  auth: any
  login: (pass: string, username: string) => Promise<any>
  logout: () => void
  forgotPassword: (username: string) => Promise<any>
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<any>
  init: () => Promise<any>
}

export const authStore = create<AuthStoreType>((set: any, get: any) => {
  return {
    user: null, // this is the cognito user obj
    userInfo: null, // this is the user from the user tabel
    isAdmin: false,
    isApproved: false,
    auth: {
      accessToken: '',
      expiresIn: '',
      issuedAt: '',
      refreshToken: '',
      tokenType: '',
    },

    logout: () => {
      console.log(' authStore > logout = ')
      Auth.signOut().then((res) => {
        window.location.replace('/')
      })
    },

    //
    // called on signin and when a logged in user revistes
    // this handles setting up a user correctly
    //
    setAuthenticatedUser: (authResponse: UserType) => {
      //const groups = authResponse.signInUserSession.accessToken.payload['cognito:groups']

      /*const isAdmin =
        groups &&
        groups.filter((groupName: string) => {
          return groupName === 'masters'
        }).length === 1

      const isApproved =
        groups &&
        groups.filter((groupName: string) => {
          return groupName === 'customers'
        }).length === 1
          ? true
          : false
*/
      set({ user: authResponse as UserType })

      //set({ user: authResponse.attributes, isAdmin: isAdmin, isApproved: isApproved, userInfo: userInfo })
    },

    login: (email: string, password: string) => {
      const setAuthenticatedUser = get().setAuthenticatedUser

      return Auth.signIn(email, password).then((res) => {
        console.log(' authStore > res = ', res)
        setAuthenticatedUser(res)
        return res
      })
    },

    init: async () => {
      const setAuthenticatedUser = get().setAuthenticatedUser
      return Auth.currentAuthenticatedUser({ bypassCache: true }).then((authResult) => {
        setAuthenticatedUser(authResult)
        return authResult
      })
    },
    forgotPassword: (username) => {
      return Auth.forgotPassword(username)
        .then((data) => {
          console.log(data)
          return data
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    },
    forgotPasswordSubmit: (username, code, newPassword) => {
      return Auth.forgotPasswordSubmit(username, code, newPassword)
        .then((data) => {
          console.log(data)
          return data
        })
        .catch((err) => {
          return err
        })
    },
  }
})
