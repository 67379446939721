import { UserSwitchOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Radio, RadioChangeEvent } from 'antd'
import { SetStateAction, useState } from 'react'

import { mutate as mutateGlobal } from 'swr'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'

type MoveProps = {
  isModalOpen: boolean
  setIsMoveModalOpen: (e: SetStateAction<boolean>) => void
  subjectData: any
  nextPhase: any
  mutateSubjectData: any
}

export const MoveModal = ({
  isModalOpen,
  setIsMoveModalOpen,
  subjectData,
  nextPhase,
  mutateSubjectData,
}: MoveProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)
  const [selectedBodyParts, setSelectedBodyParts] = useState<string>()

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const projectId = subjectData.projectId._id

    const obj = {
      subjectId: subjectData._id,
      bodyPart: selectedBodyParts,
      projectId: projectId,
    }

    return post(ENDPOINTS.SUBJECTS.ACTION.move(projectId), obj, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateSubjectData()
          setIsMoveModalOpen(false)
          setConfirmLoading(false)
          setSelectedBodyParts(undefined)
          setOpenPop(false)
          mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectData._id))
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setIsMoveModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
  }

  const handleMoveOk = () => {
    setIsMoveModalOpen(false)
    setOpenPop(false)
  }

  const handleMoveCancel = () => {
    setIsMoveModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
  }

  const cancel = () => {
    setOpenPop(false)
  }

  const onChange = (e: RadioChangeEvent) => {
    setSelectedBodyParts(e.target.value)
  }

  const plainOptions = nextPhase.bodyParts ? nextPhase.bodyParts : []

  const canMove = nextPhase.bodyParts
    ? (nextPhase.bodyParts.length > 0 && selectedBodyParts !== undefined) ||
      (nextPhase.bodyParts.length === 0 && selectedBodyParts !== undefined)
    : false

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <UserSwitchOutlined className="me-2" style={{ fontSize: '22px' }} />
            Move subject to the next Phase - {nextPhase.title}
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleMoveOk}
        onCancel={handleMoveCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal} className="me-2">
              Cancel
            </Button>
            <Popconfirm
              title={'Confirmation: Move the subject to the next phase - ' + nextPhase.title}
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button type="primary" onClick={showPopconfirm} disabled={plainOptions.length > 0 ? !canMove : false}>
                Move subject to next phase - {nextPhase.title}
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <div className="row">
          <p className="mt-2">
            {nextPhase?.bodyParts?.length > 0
              ? 'You are about to move the subject to the next phase of the study. Please select the target body part for the subject:'
              : 'You are about to move the subject to the next phase of the study.'}
          </p>

          <div className="mt-3">
            <Radio.Group onChange={onChange}>
              {plainOptions.map((option, index) => {
                return (
                  <Radio value={option ? option : ''} key={index}>
                    {option ? option : ''}
                  </Radio>
                )
              })}
            </Radio.Group>
          </div>
          {nextPhase?.bodyParts?.length > 0 ? (
            <p className="mt-3">
              Note: During the next phase, the subject will only report pain for the selected body part.
            </p>
          ) : null}
        </div>
      </Modal>
    </>
  )
}
