import { UserSwitchOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Radio, RadioChangeEvent } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { SetStateAction, useState } from 'react'

import { mutate as mutateGlobal } from 'swr'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'

type MovePreviousProps = {
  isModalOpen: boolean
  setIsMovePreviousModalOpen: (e: SetStateAction<boolean>) => void
  subjectData: any
  previousPhase: any
  mutateSubjectData: any
}

export const MovePreviousModal = ({
  isModalOpen,
  setIsMovePreviousModalOpen,
  subjectData,
  previousPhase,
  mutateSubjectData,
}: MovePreviousProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)
  const [selectedBodyParts, setSelectedBodyParts] = useState<string>()
  const [moveBackReason, setMoveBackReason] = useState<string>('')

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const projectId = subjectData.projectId._id

    const obj = {
      subjectId: subjectData._id,
      bodyPart: selectedBodyParts,
      projectId: projectId,
      reasonDescription: moveBackReason,
    }

    return post(ENDPOINTS.SUBJECTS.ACTION.movePrevious(projectId), obj, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateSubjectData()
          setIsMovePreviousModalOpen(false)
          setConfirmLoading(false)
          setSelectedBodyParts(undefined)
          setOpenPop(false)
          setMoveBackReason('')
          mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectData._id))
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setIsMovePreviousModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
    setMoveBackReason('')
  }

  const handleMoveOk = () => {
    setIsMovePreviousModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
    setMoveBackReason('')
  }

  const handleMoveCancel = () => {
    setIsMovePreviousModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
    setMoveBackReason('')
  }

  const cancel = () => {
    setOpenPop(false)
  }

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value)
    setSelectedBodyParts(e.target.value)
  }
  const plainOptions = previousPhase.bodyParts ? previousPhase.bodyParts : []

  const hasReason = moveBackReason.length < 5 ? false : true

  const canMove = selectedBodyParts !== undefined && hasReason

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMoveBackReason(e.target.value)
  }

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <UserSwitchOutlined className="me-2" style={{ fontSize: '22px' }} />
            Move subject to the previous Phase - {previousPhase.title}
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleMoveOk}
        onCancel={handleMoveCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal} className="me-2">
              Cancel
            </Button>
            <Popconfirm
              title={'Confirmation: Move the subject back to the previous phase - ' + previousPhase.title}
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button
                type="primary"
                onClick={showPopconfirm}
                disabled={moveBackReason.length < 5 || plainOptions.length > 0 ? !canMove : false}
              >
                Move subject to the previous phase - {previousPhase.title}
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <div className="row">
          <p className="mt-2">
            You are about to revert the subject to the previous phase. The subject will now answer the questionnaire set
            up for that specific phase.
          </p>

          {previousPhase?.bodyParts?.length > 0 ? (
            <div className="mt-3">
              <Radio.Group onChange={onChange} value={selectedBodyParts}>
                {plainOptions.map((option, index) => {
                  return (
                    <Radio value={option ? option : ''} key={index}>
                      {option ? option : ''}
                    </Radio>
                  )
                })}
              </Radio.Group>
            </div>
          ) : null}

          <div>
            <p className="mt-3">
              Please provide a written reason for the revert action, it will be recorded in the Audit Trail.
            </p>
            <TextArea
              placeholder="Please provide justification for moving the subject back"
              rows={2}
              className="mt-3"
              onChange={handleTextChange}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
