import { SmileOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { SetStateAction, useState } from 'react'

import { useParams } from 'react-router-dom'
import { mutate as mutateGlobal } from 'swr'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'

type ReQualifyProps = {
  isModalOpen: boolean
  setIsReQualifyModalOpen: (e: SetStateAction<boolean>) => void
  subjectId: string
  mutateSubjectData: any
}

export const ReQualifyModal = ({
  isModalOpen,
  setIsReQualifyModalOpen,
  subjectId,
  mutateSubjectData,
}: ReQualifyProps) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)
  const [reQualifyReason, setReQualifyReason] = useState<string>('')

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const obj = { projectId: projectId, subjectId: subjectId, reasonDescription: reQualifyReason }

    return post(ENDPOINTS.SUBJECTS.ACTION.requalify(projectId), obj, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateSubjectData()
          mutateGlobal(ENDPOINTS.SUBJECTS.get(projectId, siteId))
          setIsReQualifyModalOpen(false)
          setReQualifyReason('')
          setConfirmLoading(false)
          setOpenPop(false)
          mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectId))
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
    setReQualifyReason('')
  }

  const handleDisqualifyOk = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
    setReQualifyReason('')
  }

  const handleDisqualifyCancel = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
    setReQualifyReason('')
  }

  const cancel = () => {
    setOpenPop(false)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReQualifyReason(e.target.value)
  }

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <SmileOutlined className="me-2" style={{ color: 'green', fontSize: '22px' }} />
            Subject reactivation
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleDisqualifyOk}
        onCancel={handleDisqualifyCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal}>Cancel</Button>
            <Popconfirm
              title="Confirmation: Reactivate the subject?"
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button className="ms-2" type="primary" onClick={showPopconfirm} disabled={reQualifyReason.length < 5}>
                Reactivate Subject
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <p className="mt-2">
          You are about to reactivate a subject, initiating ePRO data collection. Please provide a written reason in the
          field below for the reactivation. The subject will be reinstated in the phase from which their participation
          was terminated.
        </p>
        <TextArea placeholder="Reactivation reason" rows={2} className="mt-3" onChange={handleTextChange} />
      </Modal>
    </>
  )
}
