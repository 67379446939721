import { getReq } from '../service/API'
import useSWR from 'swr'

//
// By 19/2 -2024 Please stop using this hook. Make a dedicated one for the endpoint
//
export const useById = (endpoint: string, id: string) => {
  const p = endpoint && id ? endpoint + '/' + id : null

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
