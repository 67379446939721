/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { Button } from 'antd'
import Alert from 'antd/lib/alert'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { CRUD_VIEW_TYPE, SiteType } from '../../Types'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { FormJustification } from '../../components/form/FormJustification'
import { openNotification } from '../../components/notificationToast'
import { useTrialStaffById } from '../../hooks/useTrialStaffById'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { SideBarAddSites } from './SideBarAddSites'
import { SiteBarSiteList } from './SideBarSiteList'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: CRUD_VIEW_TYPE
}

const SITES_FORM_KEY = 'sites'

export const TrialStaffSiteCRUD = ({ onClose, closeDrawer, viewType = CRUD_VIEW_TYPE.CREATE }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { data, mutate } = useTrialStaffById(projectId, userId)

  const formHook = useForm()

  const { handleSubmit, getValues, setValue } = formHook

  const [errorState, setErrorState] = useState<any>()
  const [showAddSiteDrawer, setAddSiteDrawer] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const initSites = useMemo(() => {
    const defaultSiteArr = data?.sites || []
    return viewType === CRUD_VIEW_TYPE.CREATE ? [] : defaultSiteArr
  }, [viewType, data])

  useEffect(() => {
    const a = initSites.map((site) => site._id)

    formHook.register(SITES_FORM_KEY)
    formHook.setValue(SITES_FORM_KEY, viewType === CRUD_VIEW_TYPE.VIEW ? [] : a)
  }, [formHook, viewType, initSites])

  const [selectedSites, setSelectedSites] = useState<SiteType[]>(initSites)

  useEffect(() => {
    const initSites = data?.sites || []
    const len = initSites.filter((site, index) => selectedSites[index]?._id === site._id).length

    // it is dirty if the length of the selected sites is different from the initial sites
    // if the length is the same, we need to check if the selected sites are the same as the initial sites
    const isDirty = len !== initSites.length || selectedSites.length !== initSites.length
    setIsDirty(isDirty)
  }, [selectedSites, data?.sites])

  const onSubmit = (formData) => {
    const justification = formData.justification
    const sites = formData.sites
    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'

    const updatedSendData = {
      staffId: userId,
      sites: sites,
      projectId: projectId,
      justification: justification,
    }
    const path = ENDPOINTS.TRIAL_STAFF.ACTION.updateSites(projectId)

    setIsLoading(true)

    return post(path, updatedSendData, method)
      .then(() => {
        const title = viewType === CRUD_VIEW_TYPE.UPDATE ? 'Update successfully' : 'Created successfully'
        const msg =
          viewType === CRUD_VIEW_TYPE.UPDATE
            ? 'Sites have been successfully updated'
            : 'Sites have been successfully added'

        openNotification(title, msg, 'happy')
        setIsLoading(false)

        mutate()

        if (onClose) {
          onClose()
        }

        if (closeDrawer) {
          closeDrawer(false)
          setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorState(error)
      })
  }

  const updateSelectedSites = (theSites) => {
    const arrOfIds = theSites.map((item, index) => {
      return item._id
    })

    setValue(SITES_FORM_KEY, arrOfIds)
    setSelectedSites(theSites)
  }

  const removeSelectedSite = (id) => {
    const cur = getValues(SITES_FORM_KEY)

    const updated = cur.filter((item) => {
      return item !== id
    })

    const updatedSites: SiteType[] = selectedSites.filter((item) => {
      return item._id !== id
    })

    setValue(SITES_FORM_KEY, updated)
    setSelectedSites(updatedSites)
  }

  const open = () => {
    setAddSiteDrawer(true)
  }

  const closeAddSiteDrawer = () => {
    setAddSiteDrawer(false)
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  return (
    <>
      {data.type === 'CRA' ? (
        <>
          {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
            <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="px-3">
                <p className="mb-1 opacity-75">Selected CRA:</p>
                <h5 className="fw-bold text-truncate">{data ? data.email : '-'}</h5>
                <p>
                  Please select the site, the user needs access to. When assigning sites to users, please ensure, they
                  are given access only to the specific sites required for their tasks to ensure compliance.
                </p>
                <p className="mt-2">
                  Access to sites can be revoked at any time for necessary changes. Please provide a written reason for
                  the Audit Trail.
                </p>
              </div>

              <div className="col-12 mb-4 mt-4">
                <SiteBarSiteList
                  open={open}
                  selectedSites={selectedSites}
                  removeSelectedSite={removeSelectedSite}
                  viewType={CRUD_VIEW_TYPE.CREATE}
                ></SiteBarSiteList>
              </div>

              {isDirty ? (
                <div className="col-12 mb-3 ">
                  {/*Justification*/}
                  <FormJustification formHook={formHook} />
                </div>
              ) : null}
              <div className="col-12 d-flex justify-content-end mt-3">
                <Button
                  onClick={handleSubmit(onSubmit, onError)}
                  type="primary"
                  loading={isLoading}
                  disabled={!isDirty}
                >
                  {viewType === CRUD_VIEW_TYPE.CREATE ? 'Create user' : 'Save changes'}
                </Button>
              </div>

              <div className="col-12 mt-3">
                {errorState ? (
                  <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
                ) : (
                  <></>
                )}
              </div>
            </form>
          ) : (
            <div className="">
              <h1>not used</h1>
            </div>
          )}
          {showAddSiteDrawer ? (
            <DrawerContainer setVisible={closeAddSiteDrawer} projectId={''} width={550} to="." title="Site list">
              <SideBarAddSites
                projectId={projectId}
                setSelectedSites={updateSelectedSites}
                selectedSites={selectedSites}
              />
            </DrawerContainer>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="px-3">
          <p>Site selection is only enabled for trial staff of type CRA.</p>
        </div>
      )}
    </>
  )
}
