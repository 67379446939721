import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useState } from 'react'
import { useIdleTimeout } from '../../util/useIdleTimeout'

import { inactivityInSeconds, logoutAfterSeconds } from '../../constants'

export const TimeoutModal = () => {
  const [openModal, setOpenModal] = useState(false)
  //const [remainingTime, setRemainingTime] = useState<any>(null) // State to store remaining time

  const handleIdle = () => {
    setOpenModal(true)
  }

  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    showPromptAfter: inactivityInSeconds,
    logoutAfter: logoutAfterSeconds,
  })

  // useEffect(() => {
  //   // Update remaining time state on each tick of the timer
  //   const timerInterval = setInterval(() => {
  //     setRemainingTime(idleTimer.getRemainingTime())
  //   }, 1000)

  //   return () => clearInterval(timerInterval) // Cleanup on unmount
  // }, [idleTimer])

  // console.log('_______remainingTime_________', remainingTime)

  const handleCancel = () => {
    setOpenModal(false)
    idleTimer.reset()
  }

  const stay = () => {
    setOpenModal(false)
    idleTimer.reset()
  }

  const activityModal = (
    <Modal
      open={openModal}
      /*title={<p>Inactivity has been detected</p>}*/
      width={550}
      //bodyStyle={{ minHeight: 200 }}
      onOk={stay}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <div key="stayBtn" className="d-flex justify-content-center mb-4 mt-4">
          <Button type="primary" key="okButton" onClick={stay}>
            <p>Stay signed in</p>
          </Button>
        </div>,
      ]}
    >
      <div className="container-fluid ">
        <div className="row" style={{}}>
          <div className="col-12 d-flex justify-content-center">
            <ExclamationCircleOutlined className="me-2 mt-6" style={{ fontSize: '45px', color: 'orange' }} />
          </div>
          <div className="col-12 ">
            <div className="d-flex justify-content-center mt-4">
              <h3>Your session is about to expire</h3>
            </div>
            <p className="fw-bold text-center my-2">
              To protect the information in system and be GDPR compliant, we will automaticlly logout your user when
              inactivity has been detected
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )

  return <> {activityModal}</>
}
