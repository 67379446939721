/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from './components/display/Layout'

import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { TrailStaffPage } from './pages/trailstaff/TrailStaffPage'
import { ProjectList } from './pages/project/ProjectList'
import { ProjectView } from './pages/project/ProjectView'

import { CLINICIAN_SUBJECT_LIST_PAGE, CLINICIAN_TRIAL_STAFF_PAGE, SITE_LIST_PAGE, SUBJECT_LIST_PAGE } from './routes'
import { SiteListPage } from './pages/site/SiteListPage'
import { SubjectTrialstaffPage } from './pages/subjects/SubjectTrialstaffPage'
import { authStore } from './store/auth/authStore'
import { TOKEN_USER_TYPES } from './Types'
import { SubjectClnicianPage } from './pages/subjects/SubjectClinicianPage'
import { ProjectViewClinician } from './pages/project/ProjectViewClinician'
import { TrailStaffClinicianPage } from './pages/trailstaff/TrailStaffClinicianPage'
import { TimeoutModal } from './components/display/TimeoutModal'
import { VERSION } from './constants'

console.log('Main > VERSION = ', VERSION)
export const Main = () => {
  const user: any = authStore((state) => state.user)
  const userType = user?.attributes['custom:userType']

  return (
    <>
      <TimeoutModal />
      <Routes>
        <Route path={'/'} element={<Layout />}>
          <Route
            path={'/'}
            element={
              <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
                <ProjectList userType={userType} />
              </Suspense>
            }
          />

          {/*    ONLY THIS the ProjectClinician or ProjectTrialStaff should be rendere not both   */}
          {userType === TOKEN_USER_TYPES.CLINICIAN ? (
            <Route path={'/project/:projectId/site/:siteId/*'} element={<ProjectClinician></ProjectClinician>} />
          ) : null}

          {/*
          OR THIS
          the ProjectClinician or ProjectTrialStaff should be rendere not both
          */}
          {userType === TOKEN_USER_TYPES.TRIAL_STAFF ? (
            <Route path={'/project/:projectId/*'} element={<ProjectTrialStaff></ProjectTrialStaff>} />
          ) : null}
        </Route>

        <Route path={'*'} element={<Navigate replace to="/" />} />
      </Routes>
    </>
  )
}

const ProjectClinician = () => {
  return (
    <>
      <Routes>
        <Route
          path={'/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
              <ProjectViewClinician></ProjectViewClinician>
            </Suspense>
          }
        />

        <Route
          path={CLINICIAN_TRIAL_STAFF_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
              <TrailStaffClinicianPage></TrailStaffClinicianPage>
            </Suspense>
          }
        />

        <Route
          path={CLINICIAN_SUBJECT_LIST_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
              <SubjectClnicianPage></SubjectClnicianPage>
            </Suspense>
          }
        />
        <Route
          path={SITE_LIST_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
              <SiteListPage></SiteListPage>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}

//
// TRAIAL STAFF
//---------------------------------------------------------------------------------------

const ProjectTrialStaff = () => {
  return (
    <Routes>
      <Route
        path={'overview/*'}
        element={
          <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
            <ProjectView />
          </Suspense>
        }
      />
      <Route
        path={'trialstaff/*'}
        element={
          <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
            <TrailStaffPage></TrailStaffPage>
          </Suspense>
        }
      />

      <Route
        path={SUBJECT_LIST_PAGE.path}
        element={
          <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
            <SubjectTrialstaffPage></SubjectTrialstaffPage>
          </Suspense>
        }
      />
      <Route
        path={SITE_LIST_PAGE.path}
        element={
          <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
            <SiteListPage></SiteListPage>
          </Suspense>
        }
      />
    </Routes>
  )
}
