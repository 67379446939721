import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useSubject = (projectId: string, subjectId: string) => {
  const path = ENDPOINTS.SUBJECTS.getSubject(projectId, subjectId)

  const { data, error, mutate } = useSWR(path, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
