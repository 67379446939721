import { USER_TYPES } from './Types'

export type PageType = {
  title: string
  to: string
  path: string
  visible: boolean
  gotoPage: (...rest) => string
}

//---------------------------------------------------------------------------------------
//
// CLINICIAN ROUTES
//
//---------------------------------------------------------------------------------------

export const CLINICIAN_OVERVIEW_PAGE: PageType = {
  title: 'Study overview',
  path: `overview`,
  to: `/site/:siteId`,
  visible: true,
  gotoPage: (projectId, siteId) => {
    return `/project/${projectId}/site/${siteId}`
  },
}

export const CLINICIAN_SUBJECT_LIST_PAGE: PageType = {
  title: 'Subjects',
  path: `/subject/*`,
  to: `/subject`,
  visible: true,
  gotoPage: (projectId: string, siteId: string) => {
    return `/project/${projectId}/site/${siteId}/subject`
  },
}

export const CLINICIAN_TRIAL_STAFF_PAGE: PageType = {
  title: 'Trial staff',
  path: `/trialstaff/*`,
  to: `/trialstaff`,
  visible: true,
  gotoPage: (projectId, siteId) => {
    //return `/project/${id}/trialstaff`
    return `/project/${projectId}/site/${siteId}/trialstaff`
  },
}

//---------------------------------------------------------------------------------------

export const OVERVIEW_PAGE: PageType = {
  title: 'Study overview',
  path: `overview`,
  to: `/overview`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/overview`
  },
}

export const DM_OVERVIEW: PageType = {
  title: 'Reports',
  path: `overview`,
  to: `/overview`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/overview`
  },
}

export const TRIAL_STAFF_PAGE: PageType = {
  title: 'Trial staff',
  path: `trialstaff/*`,
  to: `/trialstaff`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/trialstaff`
  },
}

export const SUBJECT_LIST_PAGE: PageType = {
  title: 'Subjects',
  path: `subject/*`,
  to: `/subject`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/subject`
  },
}

export const SITE_LIST_PAGE: PageType = {
  title: 'Sites',
  path: `sites/*`,
  to: `/sites`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/sites`
  },
}

export const getRoutes = (userType: string) => {
  // CTM
  if (userType === USER_TYPES.CTM) {
    return [OVERVIEW_PAGE, TRIAL_STAFF_PAGE, SITE_LIST_PAGE, SUBJECT_LIST_PAGE]
  }

  // CRA
  if (userType === USER_TYPES.CRA) {
    return [OVERVIEW_PAGE, TRIAL_STAFF_PAGE, SITE_LIST_PAGE, SUBJECT_LIST_PAGE]
  }

  // DM
  if (userType === USER_TYPES.DM) {
    return [DM_OVERVIEW, TRIAL_STAFF_PAGE, SITE_LIST_PAGE, SUBJECT_LIST_PAGE]
  }

  // CLINICIAN
  if (userType === USER_TYPES.CLINICIAN) {
    return [CLINICIAN_OVERVIEW_PAGE, CLINICIAN_TRIAL_STAFF_PAGE, CLINICIAN_SUBJECT_LIST_PAGE]
  }

  return []
}
