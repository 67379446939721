import { TRIAL_STAFF_TYPE_ENUM } from '../../Types'

export const getTrialStaffPiiConfig = () => {
  return trialstaffPiiConfig
}

const trialstaffPiiConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add clinician name',
    label: 'Name',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'phone',
    type: 'PHONE',
    placeholder: 'Add clinician phone number',
    label: 'Phone number',
    prefix: '',
    postfix: '*',
    value: '',
    validation: {
      validate: (value) => {
        if (!value.tel && !value.countryCode) {
          return 'This is required.'
        }

        if (!value.tel) {
          return 'Phone number is required.'
        }
        if (!value.countryCode) {
          return 'Country code is required.'
        }

        return value || 'This is required.'
      },
    },
    errorMsg: 'Phone number required',
    disabled: false,
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add clinician email',
    label: 'Email',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
    disabled: false,
  },
  {
    id: 'type',
    type: 'DROPDOWN',
    placeholder: 'Select manager type',
    label: 'Trial staff type',
    prefix: '',
    postfix: '*',
    value: '',
    options: [
      { value: TRIAL_STAFF_TYPE_ENUM.CTM, label: 'CTM - Clinical Trial Manager' },
      { value: TRIAL_STAFF_TYPE_ENUM.CRA, label: 'CRA - Clinical Research Associate' },
      { value: TRIAL_STAFF_TYPE_ENUM.DM, label: 'DM - Data Manager' },
    ],
    validation: { required: true },
    errorMsg: 'A type must be selected',
    disabled: true,
  },
  {
    id: 'jobTitle',
    type: 'TEXT',
    placeholder: 'Add job title (optional)',
    label: 'Job title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
  },
]
