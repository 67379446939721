import { SiteType } from '../../Types'

type props = {
  data: SiteType[]
}

export const SiteListRender = ({ data }: props) => {
  return (
    <>
      {data.map((item: SiteType, index) => {
        const phone = item?.phone?.tel ? item.phone.countryCode + item.phone.tel : '-'
        return (
          <div
            key={index}
            className="col-12 bg-white mb-5 pb-4 px-0 "
            style={{ boxShadow: '0px 4px 10px -4px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="row px-4 py-2 mt-3">
              <div className="col-11">
                <div className="ps-2 " style={{ width: '40%' }}>
                  <h5 className="p-small mb-1 opacity-50 ">Site number/title</h5>
                  <h4 className="mb-2 fw-bold text-black text-truncate">
                    {item.siteNumber ? item.siteNumber + ' - ' + item.title : '-'}
                  </h4>
                </div>
              </div>
              <div className="col-1 d-flex justify-content-end align-items-center">
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: item.active ? '#89B9AD' : '#FD8A8A',
                    borderRadius: '8px',
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="opacity-50">
                  <hr className="my-2" />
                </div>
                <div className="px-4 d-flex mt-4">
                  <div className="ps-2 my-3 " style={{ width: '25%' }}>
                    <h5 className="p-small mb-0 opacity-50 ">Primary Contact Person</h5>
                    <p className="mb-1 fw-bold text-black text text-truncate">
                      {item.contactPersonName ? item.contactPersonName : '-'}
                    </p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '40%' }}>
                    <h5 className="p-small mb-0 opacity-50 ">Primary Contact Email</h5>
                    <p className="mb-1 fw-bold text-black text text-truncate pe-5">
                      {item.contactPersonEmail ? item.contactPersonEmail : '-'}
                    </p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '35%' }}>
                    <h5 className="p-small mb-0 opacity-50 ">Primary Contact Person Job Title</h5>
                    <p className="mb-1 fw-bold text-black  text text-truncate">
                      {item.contactPersonJobTitle ? item.contactPersonJobTitle : '-'}
                    </p>
                  </div>
                </div>

                <div className="px-4 d-flex ">
                  <div className="ps-2 my-3 " style={{ width: '25%' }}>
                    <h5 className="p-small mb-0 opacity-50 ">Address</h5>
                    <p className="mb-1 fw-bold text-black  text text-truncate">{item.address ? item.address : '-'}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '40%' }}>
                    <h5 className="p-small mb-0 opacity-50 ">Primary Contact Phone</h5>
                    <p className="mb-1 fw-bold text-black  text text-truncate">{phone}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '35%' }}>
                    <h5 className="p-small mb-0 opacity-50 ">Timezone</h5>
                    <p className="mb-1 fw-bold text-black  text text-truncate">{item.timeZone ? item.timeZone : '-'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

/*return (
  <>
    {data.map((item, index) => {
      return (
        <div
          key={index}
          className="col-12 bg-white  d-flex align-items-center border-bottom"
          style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="ps-2 my-3 " style={{ width: '40%', maxWidth: '250px' }}>
            <p className="p-mini opacity-50 ">Site number</p>
            <p className="mb-1 fw-bold text-black  text-truncate">{item.siteNumber ? item.siteNumber : '-'}</p>
          </div>

          <div className="ps-2 my-3 " style={{ width: '50%', maxWidth: '420px' }}>
            <p className="p-mini opacity-50 ">Site name</p>
            <p className="mb-1 fw-bold text-black  text text-truncate">{item.title ? item.title : '-'}</p>
          </div>

          <div className="ps-2 my-3 " style={{ width: '30%', maxWidth: '200px' }}>
            <p className="p-mini opacity-50 ">Active status</p>
            <p className="mb-1 ms-4">{item.active === true ? <Badge color="green" /> : <Badge color="red" />}</p>
          </div>

          <div className="d-flex justify-content-end me-3 " style={{ width: '20%' }}>
            <Link to={item._id} className="">
              <Button>View</Button>
            </Link>
          </div>
        </div>
      )
    })}
  </>
)*/
