import { Link } from 'react-router-dom'
import { Badge, Button, Tooltip } from 'antd'

type props = {
  data: any[]
  linkPath?: string
}

export const TrialStaffListRender = ({ data, linkPath = './' }: props) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="col-12 bg-white  d-flex align-items-center border-bottom"
            style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="ps-2 my-3 " style={{ width: '30%' }}>
              <p className="p-mini opacity-50 ">Name</p>
              <p className="mb-1 fw-bold   text-truncate">{item.name ? item.name : '-'}</p>
            </div>

            <div className="ps-2 my-3 " style={{ width: '25%' }}>
              <p className="p-mini opacity-50 ">Email</p>
              <Tooltip placement="topLeft" title={item.email ? item.email : '-'}>
                <p className="mb-1 fw-bold text-black text text-truncate">{item.email ? item.email : '-'}</p>
              </Tooltip>
            </div>

            <div className="ps-2 my-3 " style={{ width: '20%' }}>
              <p className="p-mini opacity-50 ">Status</p>
              <div className="d-flex">
                <p className="mb-1 ms-2 ps-1 fw-bold text-black ">
                  {item.status ? item.status === 'ACTIVE' ? <Badge color="green" /> : <Badge color="red" /> : ''}
                </p>
              </div>
            </div>

            <div className="ps-2 my-3 " style={{ width: '15%' }}>
              <p className="p-mini opacity-50 ">Job Title</p>
              <p className="mb-1 fw-bold text-black  text-truncate">{item.jobTitle ? item.jobTitle : '-'}</p>
            </div>

            <div className="d-flex justify-content-end me-3 " style={{ width: '10%' }}>
              {linkPath ? (
                <Link to={linkPath + item._id} className="">
                  <Button>View</Button>
                </Link>
              ) : null}
            </div>
          </div>
        )
      })}
    </>
  )
}
