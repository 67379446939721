/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18/09/2023.
 */
import React from 'react'

type Props = {
  label: string
  title: string
  style?: React.CSSProperties
  className?: string
  type?: string
  comp?: React.ReactNode | React.ReactNode[]
}

export const ListItemContent = ({ label, title, style, className, type, comp = null }: Props) => {
  const cl = className ? className : 'ps-2 my-3 '

  return (
    <div className={cl} style={style}>
      <p className="p-mini opacity-50 fw-bold" style={{ letterSpacing: 0.2 }}>
        {label.toUpperCase()}
      </p>

      {!comp && label && type === 'danger' ? <p className="mb-1 fw-bold text-danger ">{title}</p> : null}

      {!comp && label && type !== 'danger' ? (
        <p className="mb-1 fw-bold  " style={{ fontSize: 16 }}>
          {title}
        </p>
      ) : null}

      {comp ? <>{comp}</> : null}
    </div>
  )
}
