import { Alert, Button, Drawer, Result } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { COUNTRY_TYPE, CRUD_VIEW_TYPE, PROJECT_STATES } from '../../Types'
import { FormLabel } from '../../components/form/FormLabel'
import { getFormType } from '../../components/form/formFactory'
import { useProjectById } from '../../hooks/useProjectById'
import { getReq, post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { isValidEmail } from '../../util/isValidEmail'
import { isValidPhone } from '../../util/isValidPhone'
import { populateConfig } from '../../util/populateConfig'
import { getSubjectPiiConfig } from './subjectpiiConfig'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { defaultCountryCode, defaultEmailAddress, defaultPhoneNumber } from '../../constants'

export const SubjectPii = ({ me }) => {
  const [viewDrawer, setViewDrawer] = useState<boolean>(false)

  const openView = () => {
    if (!viewDrawer) {
      setViewDrawer(true)
    }
  }

  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center ">
      <div className="container-fluid px-4 py-5 bg-white rounded-2">
        <div className="row col-12">
          <div className="mb-2">
            <h3>Subject Personal Information</h3>
            <hr />
          </div>

          {me['custom:userType'] !== 'CLINICIAN' ? (
            <p>
              Your role does not have permission to view the subject's personally identifiable information. If you have
              specific inquiries, kindly reach out to the relevant personnel for assistance.
            </p>
          ) : (
            <p>
              Please be aware that, by default, the subject's personally identifiable information is not visible for
              privacy reasons. However, as a clinician, you have the required permissions to view the information. Click
              the button below to view the subject's personal information.
            </p>
          )}

          <div className="mt-4 mb-5">
            <Button
              className="px-4"
              size="large"
              type="primary"
              disabled={me['custom:userType'] !== 'CLINICIAN'}
              onClick={openView}
            >
              View Personal Information
            </Button>
          </div>
        </div>
      </div>
      <SubjectPiiView visible={viewDrawer} setVisible={setViewDrawer}></SubjectPiiView>
    </div>
  )
}

const SubjectPiiView = ({ visible, setVisible }) => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }

  const { data: projectData } = useProjectById(projectId)

  const [data, setData] = useState<any>()
  const [error, setError] = useState<any>()

  const layoutChange = (e) => {
    if (!e) {
      return close()
    }

    const p = subjectId ? ENDPOINTS.SUBJECTS.getSubjectPii(projectId, subjectId) : null

    return getReq(p)
      .then((res) => {
        setData(res.data)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const close = () => {
    setVisible(false)
  }

  const [crudDrawer, setCrudDrawer] = useState(false)

  // In case they do not have an email upon creation, we do not want to show a dummy one
  const validateEmail = data ? (data.email === defaultEmailAddress ? '-' : data.email) : '-'

  // In case they do not have an phone number upon creation, we do not want to show a dummy one
  const isDefaultPhoneNumber = defaultCountryCode + defaultPhoneNumber.toString()
  const validatePhone = data ? (data.phone_number === isDefaultPhoneNumber ? '-' : data.phone_number) : '-'

  return (
    <>
      <Drawer
        afterOpenChange={layoutChange}
        title={'View subject personal identifiable information'}
        destroyOnClose={true}
        width={500}
        placement="right"
        onClose={close}
        open={visible}
      >
        {data ? (
          <>
            <div className="">
              <p className="mb-3">
                Handle this information responsibly and in accordance with HIPAA/GDPR guidelines. To make edits, click
                the 'Edit Personal Identifiable Information' button
              </p>
              <div className="col-12 mb-4">
                <FormLabel label="Name"></FormLabel>
                <h6 className="fw-bold">{data ? data.name : '-'}</h6>
              </div>

              <div className="col-12 mb-4">
                <FormLabel label="Phone"></FormLabel>
                <h6 className="fw-bold">{validatePhone}</h6>
              </div>
              <div className="col-12 mb-4">
                <FormLabel label="Email"></FormLabel>
                <h6 className="fw-bold">{validateEmail}</h6>
              </div>

              <hr></hr>
              <div className="col-12 d-flex justify-content-end">
                <Link to="edit">
                  <Button disabled={projectData.status !== PROJECT_STATES.ACTIVE} onClick={() => setCrudDrawer(true)}>
                    Edit Personal Identifiable Information
                  </Button>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            {error ? (
              <Result status="404" title={error.type} subTitle={error.message} />
            ) : (
              <SpinnerFullHeight></SpinnerFullHeight>
            )}
          </>
        )}
        {crudDrawer ? <SubjectPiiCRUD onMount={setCrudDrawer} data={data} setData={setData}></SubjectPiiCRUD> : null}
      </Drawer>
    </>
  )
}

type Props = {
  viewType?: CRUD_VIEW_TYPE
  onMount: any
  data: any
  setData: any
}

const SubjectPiiCRUD = ({ viewType = CRUD_VIEW_TYPE.UPDATE, onMount, data, setData }: Props) => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }
  const [reason, setReason] = useState<string>('')
  const { data: projectData } = useProjectById(projectId)
  const configObj =
    viewType === CRUD_VIEW_TYPE.UPDATE ? populateConfig(getSubjectPiiConfig(), data, viewType) : getSubjectPiiConfig()

  const formHook = useForm()

  const dummyPhone = defaultCountryCode + defaultPhoneNumber
  const dummyEmail = defaultEmailAddress

  const { setValue, handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const supportedCountries: COUNTRY_TYPE[] = projectData?.country.map((item) => {
    return {
      abbreviation: item.abbreviation,
      name: item.name,
      countryCode: item.code,
    }
  })

  useEffect(() => {
    //
    // Since we do not want to show the dummy created states, we show hyphens
    //
    const IsDummyPhone: boolean = data.phone_number === dummyPhone ? true : false
    const IsDummyEmail: boolean = data.email === dummyEmail ? true : false

    if (IsDummyEmail) {
      formHook.setValue('email', '-')
    }

    if (IsDummyPhone) {
      formHook.setValue('phone_number', '-')
    }
  }, [setValue, data.phone_number, data.email, formHook, dummyEmail, dummyPhone])

  const handleReason = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReason(e.target.value)
  }
  const onSubmit = (formData, e) => {
    e.preventDefault()

    if (!isValidEmail(formData.email) || formData.email === dummyEmail) {
      setErrorState({ message: 'Please provide a valid email address.' })
      return
    }

    if (!isValidPhone(formData.phone_number) || formData.phone_number === dummyPhone) {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    }

    if (reason.length < 6) {
      setErrorState({ message: 'Please provide a reason for the change' })
      return
    }

    const updatedData = { ...data, ...formData, reason, projectId: projectId, subjectId: subjectId }

    return post(ENDPOINTS.SUBJECTS.ACTION.updatePii(projectId), updatedData, 'PUT')
      .then(() => {
        setData(formData)
        setErrorState(undefined)
        close()
      })
      .catch((error) => {
        setErrorState(error)
      })
  }

  const onError = () => {}

  const [state, setState] = useState(true)

  const close = () => {
    setState(false)
  }

  const layoutChange = (e) => {
    if (!e) {
      onMount(false)
    }
  }

  return (
    <>
      <>
        <Drawer
          afterOpenChange={layoutChange}
          title={'Edit subject personal identifiable information'}
          destroyOnClose={true}
          width={500}
          placement="right"
          onClose={close}
          open={state}
        >
          <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="col-12">
              <p className="mb-2">
                To edit the Personal Identifiable Information, make changes, provide a written reason, and press "Save
                changes" to save the updates.
              </p>
              {configObj.map((item, index) => {
                const comp = getFormType(item, index, formHook, CRUD_VIEW_TYPE.CREATE, supportedCountries)
                return comp
              })}
              <div className="mb-4">
                <FormLabel label="Please provide a reason for the desired update of personal information"></FormLabel>
                <TextArea
                  allowClear
                  placeholder="Justification for updating personal information"
                  rows={2}
                  onChange={handleReason}
                />
              </div>
              <hr></hr>
            </div>

            <div className="col-12 mb-3">
              {errorState ? (
                <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
              ) : (
                <></>
              )}
            </div>

            <div className="col-12 d-flex justify-content-end">
              <Button className="me-2" onClick={close}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
                Save changes
              </Button>
            </div>
          </form>
        </Drawer>
      </>
    </>
  )
}
