/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2023.
 */
import { SmileTwoTone } from '@ant-design/icons'
import { Badge, Button, Empty } from 'antd'
import { Link } from 'react-router-dom'
import { PROJECT_STATES, TOKEN_USER_TYPES, TokenUserTypes } from '../../Types'
import { PageHeader } from '../../components/display/PageHeader'
import { useProjects } from '../../hooks/useProjects'
import { CLINICIAN_OVERVIEW_PAGE, OVERVIEW_PAGE } from '../../routes'

type Props = { userType: TokenUserTypes }

export const ProjectList = ({ userType }: Props) => {
  const { data } = useProjects()

  return (
    <div className="container px-4 mt-0">
      <PageHeader title="Project list"> </PageHeader>
      {data.length === 0 ? (
        <div className="mt-7">
          <Empty description="There are no active projects associated with your access" />
        </div>
      ) : (
        <>
          {userType === TOKEN_USER_TYPES.CLINICIAN ? (
            <ProjectClinicianList data={data} userType={userType}></ProjectClinicianList>
          ) : null}
          {userType === TOKEN_USER_TYPES.TRIAL_STAFF ? (
            <ProjectTrialStaffList data={data}></ProjectTrialStaffList>
          ) : null}
        </>
      )}
    </div>
  )
}

const badgeColor = (status) => {
  if (status === 'DRAFT') {
    return 'orange'
  }
  if (status === 'ACTIVE') {
    return 'green'
  }
  return 'tomato'
}

const ProjectClinicianList = ({ data, userType }) => {
  // TODO: Copy pasta bad I am aware

  return (
    <div className="row" style={{}}>
      {data.map((userWithProject, index) => {
        const project = userWithProject.projects
        const sites = userWithProject.sites

        return (
          <div
            key={index}
            className="col-12 mb-4 block-shadow"
            style={{
              borderTop: '1px solid rgba(0,0,0,.15)',
              borderLeft: '1px solid #ced4da',
              borderRight: '1px solid #ced4da',
              background: '#F9FAFB',
            }}
          >
            <div className="py-4 px-2 d-flex justify-content-between align-items-center">
              <div className="w-25">
                <p className="p-small">Smart name</p>
                <p className="fw-bold">{project.displayName}</p>
              </div>

              <div className="w-25">
                <p className="p-small">Study ID</p>
                <p className="fw-bold">{project.studyId}</p>
              </div>

              <div className="w-25">
                <p className="p-small ">Project state</p>

                <p className="fw-bold">
                  <Badge className="me-1" color={badgeColor(project.status)} />
                  {project.status}
                </p>
              </div>

              <div className="w-25">
                <p className="p-small ">Country</p>
                {project.country.map((country, i) => {
                  return (
                    <p className="fw-bold" key={`country-${i}`}>
                      {country.abbreviation}
                    </p>
                  )
                })}
              </div>

              <div className="w-25">
                <p className="p-small">Role</p>
                <p className="fw-bold">{userWithProject.type}</p>
              </div>
            </div>

            {sites.length > 0 ? (
              <div className="row border-top" style={{}}>
                {sites.map((site, siteIndex) => {
                  return (
                    <div
                      key={siteIndex}
                      className="col-12 bg-white border-bottom px-4 py-4 d-flex justify-content-between align-items-center"
                      style={{
                        //background: `linear-gradient(200deg, rgba(255,255,255,1) 50%, rgba(0,95,241,.02) 100%)`,
                        background: `linear-gradient(180deg, rgba(255,255,255,1) 10%, rgba(0,0,0,.01) 100%)`,
                      }}
                    >
                      <p className="fw-bold">{site.title}</p>

                      <Link to={CLINICIAN_OVERVIEW_PAGE.gotoPage(project._id, site._id)}>
                        <Button type="primary" disabled={project.status === 'ARCHIVED' || project.status === 'DRAFT'}>
                          Access site
                        </Button>
                      </Link>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="row border-top bg-white" style={{}}>
                <div className="col-12 border-bottom px-4 py-4 d-flex justify-content-between align-items-center">
                  <p className="fw-bold">At this time there is no sites availble for you</p>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const ProjectTrialStaffList = ({ data }) => {
  // TODO: Copy pasta bad I am aware

  return (
    <div className="row" style={{}}>
      {data.map((userWithProject, index) => {
        const project = userWithProject.projectId

        const sites = userWithProject.sites

        const siteTitles = sites.map((site) => site.title)

        if (!project) {
          //
          // This might happen if project has been deleted, but the reference is still connected to the user
          //
          return null
        }

        return (
          <>
            <div key={index} className="col-12 bg-white border mb-3">
              <div className="py-4 px-2 d-flex justify-content-between align-items-center">
                <div className="w-25">
                  <p className="p-small">Smart name</p>
                  <p className="fw-bold">{project.displayName}</p>
                </div>

                <div className="w-25">
                  <p className="p-small">Study ID</p>
                  <p className="fw-bold">{project.studyId}</p>
                </div>

                <div className="w-25">
                  <p className="p-small ">Project state</p>

                  <p className="fw-bold">
                    <Badge className="me-1" color={badgeColor(project.status)} />
                    {project.status}
                  </p>
                </div>

                <div className="w-25">
                  <p className="p-small ">Country</p>
                  {project.country.map((country, i) => {
                    return (
                      <p className="fw-bold" key={`country-${i}`}>
                        {country.abbreviation}
                      </p>
                    )
                  })}
                </div>

                <div className="w-25">
                  <p className="p-small">Role</p>
                  <p className="fw-bold">{userWithProject.type}</p>
                </div>

                {sites.length > 0 ? (
                  <div className="w-25">
                    <p className="p-small">Protocol version</p>
                    <p className="fw-bold">
                      {project.activeProtocol ? project.activeProtocol.title : 'Protocol pending'}
                    </p>
                  </div>
                ) : null}

                {/*  ------  CTM VIEW  ------- */}
                {userWithProject.type !== 'CRA' ? (
                  <div className="w-25 d-flex justify-content-end">
                    <Link to={'/project/' + project._id + OVERVIEW_PAGE.to}>
                      <Button type="primary" disabled={project.status === PROJECT_STATES.DRAFT}>
                        View
                      </Button>
                    </Link>
                  </div>
                ) : null}
              </div>
              {/* --------- CRA VIEW ------------------*/}
              {userWithProject.type === 'CRA' && sites.length > 0 ? (
                <div className="row border-top" style={{}}>
                  <div className="col-12 border-bottom px-4 py-4 d-flex justify-content-between align-items-center">
                    <p className="fw-bold">{siteTitles.join(', ')}</p>
                    <div className="row">
                      <div className="col-8">
                        <Link to={'/project/' + project._id + OVERVIEW_PAGE.to}>
                          <Button type="primary" disabled={project.status === PROJECT_STATES.DRAFT}>
                            View
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {userWithProject.type === 'CRA' && sites.length === 0 ? (
                <div className="row border-top bg-white" style={{}}>
                  <div className="col-12 border-bottom px-4 py-4 d-flex justify-content-between align-items-center">
                    <p className="fw-bold">No site access. Please contact your CTM to assign you to a site.</p>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )
      })}
      <div className="col-12 d-flex justify-content-end">
        <p className="p-small">
          If you require assistance, please reach out to our support team at
          <b> support@anneapro.com </b>
          <SmileTwoTone style={{ fontSize: 16 }} />
        </p>
      </div>
    </div>
  )
}
