/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/02/2024.
 */
import React from 'react'
import { SubjectType } from '../../Types'
import { useConfig } from '../../hooks/useConfig'
import { format } from 'date-fns'
import { ReactComponent as SubjectIcon } from '../../assets/subject_icon.svg'

type Props = { subjectData: SubjectType; child: React.ReactNode | null }

export const SubjectDetailsView = ({ subjectData, child }: Props) => {
  const { data: config } = useConfig()

  const displayLangArr = config.languages.filter((lang) => {
    return lang.isoCode.toLowerCase() === subjectData.language.toLowerCase()
  })

  const displayLang = displayLangArr.length > 0 ? displayLangArr[0].name : '-'

  const initiationDate = format(new Date(subjectData.createdAt), 'd MMM yyyy')

  const statsArr = [
    { label: 'Subject:', value: subjectData.displayId },
    /*{ label: 'Site no. / title', value: subjectData.siteId.siteNumber + ' - ' + subjectData.siteId.title },*/
    { label: 'Site No.:', value: subjectData.siteId ? subjectData.siteId.title : 'N/A' },
    { label: 'Body part:', value: !subjectData.bodyPart ? null : subjectData.bodyPart },
    { label: 'Language:', value: displayLang },
    { label: 'Initiation:', value: initiationDate },
    { label: 'Status:', value: subjectData.status.toLowerCase() },
    { label: 'Phase:', value: subjectData.currentPhase.title },
    //{label: 'Progress', value: }
    //{label: 'Visit', value: }
    /*{label: 'Country', value: }*/
    /*{label: 'Randomized' , value:},*/
  ]
  return (
    <div className="row" style={{}}>
      <div className="col-10 d-flex">
        <div className="" style={{ marginLeft: -4, width: 65, height: 65 }}>
          <SubjectIcon className="" style={{ width: 66, height: 66 }}></SubjectIcon>
        </div>
        <div className="ms-3 mt-1">
          <div className="mt-2 d-flex ">
            <div className="d-flex me-1">
              <h4 className="opacity-75 fw-normal mb-0 text-truncate me-2">{statsArr[0].label}</h4>
              <h4 className="text-black fw-bold mb-0 text-truncate">{statsArr[0].value}</h4>
            </div>
            <div className="d-flex me-1">
              <h4 className="opacity-75 mb-0 fw-normal text-truncate me-1">at</h4>
              <h4 className="text-black fw-bold mb-0 text-truncate">{statsArr[1].value}</h4>
            </div>
            <div className="d-flex me-1">
              <h4 className="opacity-75 mb-0 fw-normal text-truncate me-1">in</h4>
              <h4 className="text-black fw-bold mb-0 text-truncate">{statsArr[6].value}</h4>
            </div>
            {statsArr[2].value === null ? null : (
              <div className="d-flex">
                <h5 className="opacity-75 mb-0 text-truncate me-1">with</h5>
                <h5 className="text-black fw-bold mb-0 text-truncate">{statsArr[2].value}</h5>
              </div>
            )}
          </div>
          <div className="d-flex mt-1">
            <div className="d-flex me-2">
              <p
                className=" p-small mb-0 text-truncate text-capitalize"
                style={{ color: getColor(statsArr[5].value).fontColor }}
              >
                {statsArr[5].value}
              </p>
            </div>
            <Dot className="mt-2 me-2" size={3}></Dot>
            <div className="d-flex me-2">
              <p className="text-muted p-small mb-0 text-truncate me-1">{statsArr[4].label}</p>
              <p className="text-black p-small mb-0 text-truncate">{statsArr[4].value}</p>
            </div>
            <div className="d-flex me-1">
              <p className="text-muted p-small mb-0 text-truncate me-1">{statsArr[3].label}</p>
              <p className="text-black p-small mb-0 text-truncate">{statsArr[3].value}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center">{child}</div>
    </div>
  )
}
const Dot = ({ className, size = 4 }) => {
  const cl = 'bg-black ' + className
  return <div className={cl} style={{ width: size, height: size }}></div>
}

const getColor = (status) => {
  console.log('SubjectDetailsView > status = ', status)
  if (status.toUpperCase() === 'ACTIVE') {
    return { bgColor: '#d2f5d1', borderColor: '#2ab01a', fontColor: '#249118' }
  }

  if (status.toUpperCase() === 'TERMINATED') {
    return { bgColor: '#f8dcdc', borderColor: '#e19999', fontColor: '#c92323' }
  }
  return { bgColor: '#E6F4FF', borderColor: '#1c5dce', fontColor: '#1c5dce' }
}
