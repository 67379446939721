/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/05/2023.
 */
import React, { useState } from 'react'
import { Tabs, TabsProps } from 'antd'
import { TabViewNavigator } from './TabViewNavigator'

type Props = {
  menu?: string[]
  children: React.ReactElement | React.ReactElement[]
  getPageIndex?: (index: number) => void
}

export const SubpageNavigator = ({ menu = ['one', 'two'], children, getPageIndex }: Props) => {
  const [state, setState] = useState<number>(0)
  const [direction, setDirection] = useState<number>(0)

  const items: TabsProps['items'] = menu.map((item, index) => {
    return {
      key: index.toString(),
      label: item,
    }
  })

  const onChange = (key: string) => {
    const value = parseInt(key)
    setDirection(value > state ? -200 : 200)
    setState(value)

    if (getPageIndex) {
      getPageIndex(value)
    }
  }

  return (
    <>
      <div
        className="container-fluid bg-white mb-4 "
        style={{
          borderBottom: '1px solid rgba(0,0,0,.1)',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
        }}
      >
        <div className="container px-lg-6  d-flex flex-column ">
          <div className="row ">
            <div className="col-12">
              <Tabs
                destroyInactiveTabPane={false}
                size="middle"
                indicator={{
                  size: (size) => {
                    return size + 14
                  },
                }}
                tabBarStyle={{ margin: 0, padding: 0 }}
                defaultActiveKey={state.toString()}
                items={items}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container px-lg-5 mb-6 d-flex flex-column flex-fill">
        <TabViewNavigator pageIndex={state} direction={direction}>
          {children}
        </TabViewNavigator>
      </div>
    </>
  )
}
