/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/05/2023.
 */
import { useParams } from 'react-router-dom'
import { useSubjectAudit } from '../../hooks/useSubjectAudit'
import { AuditFactory } from '../../util/auditFactory'

export const SubjectAuditTrial = () => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }

  const { data } = useSubjectAudit(projectId, subjectId)

  return (
    <div className="container-fluid">
      <AuditFactory data={data}></AuditFactory>
    </div>
  )
}
