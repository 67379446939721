export const SiteCRUD = () => {
  //const { projectId, siteId } = useParams() as { siteId: string; projectId: string }

  //const p = siteId ? ENDPOINTS.SITES.get(projectId) : ''

  //const { data } = useById(p, siteId)

  return (
    <>
      <h1>not in use</h1>
      {/*
      {data ? (
        <>
          <h1>not in use</h1>
          <div className="col-12 mb-4">
            <div className="col-12 mb-4">
              <FormLabel label="Site number"></FormLabel>
              <h6 className="fw-bold">{data?.siteNumber || '-'}</h6>
            </div>
            <FormLabel label="Site name"></FormLabel>
            <h6 className="fw-bold">{data?.title || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site address"></FormLabel>
            <h6 className="fw-bold">{data?.address || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site phone number"></FormLabel>
            <h6 className="fw-bold">{data.phone ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person name"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonName || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person email"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonEmail || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person job title"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonJobTitle || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Time zone"></FormLabel>
            <h6 className="fw-bold">{data?.timeZone || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site local date"></FormLabel>
            <h6 className="fw-bold">{data.localTime ? data?.localTime.slice(9, 17) : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site local time (24-hour clock)"></FormLabel>
            <h6 className="fw-bold">{data?.localTime ? data?.localTime.slice(0, 9) : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Active"></FormLabel>
            <h6 className="fw-bold">{data?.active === true ? 'Active' : 'Not active'}</h6>
          </div>
        </>
      ) : (
        <Empty />
      )}*/}
    </>
  )
}
