import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { SetStateAction, useState } from 'react'

import { useParams } from 'react-router-dom'
import { mutate as mutateGlobal } from 'swr'
import { TERMINATION_REASONS } from '../../../Types'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'

type DisqualifyProps = {
  isModalOpen: boolean
  setIsDisqualifyModalOpen: (e: SetStateAction<boolean>) => void
  subjectId: string
  mutateSubjectData: any
}

export const DisqualifyModal = ({
  isModalOpen,
  setIsDisqualifyModalOpen,
  subjectId,
  mutateSubjectData,
}: DisqualifyProps) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)

  const [disqualificationType, setDisqualificationType] = useState<TERMINATION_REASONS>()
  const [disqualificationReason, setDisqualificationReason] = useState<string>('')

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const obj = {
      projectId: projectId,
      subjectId: subjectId,
      reasonType: disqualificationType,
      reasonDescription: disqualificationReason,
    }

    return post(ENDPOINTS.SUBJECTS.ACTION.disqualify(projectId), obj, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateGlobal(ENDPOINTS.SUBJECTS.get(projectId, siteId))
          mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectId))
          mutateSubjectData()
          setIsDisqualifyModalOpen(false)
          setConfirmLoading(false)
          setDisqualificationType(undefined)
          setOpenPop(false)
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setIsDisqualifyModalOpen(false)
    setDisqualificationType(undefined)
    setOpenPop(false)
  }

  const handleDisqualifyOk = () => {
    setIsDisqualifyModalOpen(false)
    setDisqualificationType(undefined)
    setOpenPop(false)
  }

  const handleDisqualifyCancel = () => {
    setIsDisqualifyModalOpen(false)
    setDisqualificationType(undefined)
    setOpenPop(false)
  }

  const cancel = () => {
    setOpenPop(false)
  }

  const handleDropDownChange = (value: TERMINATION_REASONS) => {
    setDisqualificationType(value)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisqualificationReason(e.target.value)
  }

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <InfoCircleOutlined className="me-2" style={{ color: 'orange', fontSize: '22px' }} />
            End study participation
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleDisqualifyOk}
        onCancel={handleDisqualifyCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal} className="">
              Cancel
            </Button>
            <Popconfirm
              title="Confirmation: End the study participation for the subject?"
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okType="danger"
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button
                className="ms-2"
                type="primary"
                danger
                onClick={showPopconfirm}
                disabled={disqualificationType === undefined}
              >
                End study participation
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <p className="mt-2">
          You are about to end the study participation for a subject. Discontinuation stops ePRO data collection. Kindly
          select a reason from the drop-down below for the Audit Trail. The subject's device will be disabled, please
          ensure the collection and wiping of the devices.
        </p>
        <Select
          placeholder="Reason"
          style={{ width: 250 }}
          onChange={handleDropDownChange}
          options={[
            { value: TERMINATION_REASONS.DISCONTINUED, label: 'Discontinued' },
            { value: TERMINATION_REASONS.SCREEN_FAILURE, label: 'Screen failure' },
            { value: TERMINATION_REASONS.OTHER, label: 'Other' },
          ]}
          className="mt-3 me-2"
        ></Select>
        <TextArea
          placeholder="Justification for study termination"
          rows={2}
          className="mt-3"
          onChange={handleTextChange}
        />
      </Modal>
    </>
  )
}
