import { useParams } from 'react-router-dom'
import React from 'react'
import { useAssignments } from '../../hooks/useAssignment'
import { useProjectById } from '../../hooks/useProjectById'
import { Collapse, CollapseProps } from 'antd'
import { QuestionsTypes, QuestionType } from '../../Types'

export const SubjectAssignment = () => {
  const { projectId, subjectId, assignmentId } = useParams() as {
    projectId: string
    subjectId: string
    assignmentId: string
  }

  const { data: projectData } = useProjectById(projectId)
  const { data } = useAssignments(projectId, assignmentId, subjectId)
  console.log('SubjectAssignment > projectData = ', projectData)
  const defaultLang = projectData.languages[0].isoCode
  console.log('SubjectAssignment > defaultLang = ', defaultLang)
  const questions = data.questions
  console.log('SubjectAssignments > data = ', data)

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        {questions.map((question, index) => {
          const title = question.title[defaultLang]
          const body = question.body[defaultLang]

          // Revert answer list. Last answer first
          const answers = data.answers.filter((answer) => answer.questionId === question._id).reverse()

          return (
            <div key={index} className="col-12 mb-4 border-bottom pb-4">
              <h5>{title}</h5>
              <p>{body}</p>

              <AnswersFactory question={question} answers={answers} defaultLang={defaultLang} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const AnswersFactory = ({ question, answers, defaultLang }: { question: any; answers: any[]; defaultLang: string }) => {
  const type: QuestionType = question.type
  const showAnswers = question?.type !== QuestionsTypes.info

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const day = ('0' + date.getDate()).slice(-2)
    const monthIndex = date.getMonth()
    const year = date.getFullYear()
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)
    const seconds = ('0' + date.getSeconds()).slice(-2)
    const month = monthNames[monthIndex]

    return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`
  }

  const createLabel = (createdAt: string, i: number = 0) => {
    const label = i === 0 ? 'Submitted: ' : 'Answer: '
    return label + formatDate(createdAt)
  }

  /**
   For each question type:
   1. Create answers
   2. Wrap answers
   */
  const createAnswer = (type: QuestionType, answer: any) => {
    const hasValue = answer?.value
    const answerIndex = hasValue ? parseInt(answer.value) : null

    switch (type) {
      case QuestionsTypes.radio:
        // Create and wrap options
        return (
          <div>
            {question?.options.map((option, questionIndex) => {
              return (
                <RadioAnswerOption
                  key={`${answerIndex}-${questionIndex}`}
                  isSelected={answerIndex === questionIndex}
                  body={option[defaultLang]}
                />
              )
            })}
          </div>
        )
      case QuestionsTypes.nprs11pt2l:
        // Create and wrap options
        return (
          <div>
            <div className="d-flex justify-md-content-between justify-xml-content-start">
              {question?.options.intensity.map((option, questionIndex) => {
                return (
                  <NprsAnswerOption
                    key={`${answerIndex}-${questionIndex}`}
                    isSelected={answerIndex === questionIndex}
                    body={option[defaultLang]}
                  />
                )
              })}
            </div>
          </div>
        )
      default:
    }
  }

  if (showAnswers) {
    /*
    If there is ONE answer, options are wrapped in a <div>
    If there is MORE than one answer, options are wrapped in a <Collapse> creating an accordion
   */
    if (answers.length === 1) {
      // One answer
      const answer = answers[0]
      const label = createLabel(answer.createdAt)
      return (
        <div className="mt-3">
          <p className="mb-2" style={{ fontSize: '0.8rem' }}>
            {label}
          </p>
          {createAnswer(type, answer)}
        </div>
      )
    } else if (answers.length > 1) {
      // Multiple answers
      const items: CollapseProps['items'] = answers.map((answer, i) => {
        return {
          key: (i + 1).toString(),
          label: createLabel(answer.createdAt, i),
          children: <div style={{ marginTop: '-15px' }}>{createAnswer(type, answer)}</div>, // The negative top margin decreases distance between children and header label in collapse. Making layout a little more "tight"
          style: { borderBottom: '1px dotted #DDD' },
        }
      })

      return (
        <Collapse
          ghost
          accordion
          expandIconPosition={'right'}
          items={items}
          defaultActiveKey={['1']}
          size="small"
          style={{ fontSize: '0.8rem' }}
        />
      )
    }

    // If no answers to display, the question has been accessed anyway. Display the date for each "answer"
  } else {
    const elements = answers.map((answer, i) => {
      const label = createLabel(answer.createdAt, i)
      return (
        <div key={`element-${i}`} className="">
          <p className="" style={{ fontSize: '0.8rem' }}>
            {label}
          </p>
        </div>
      )
    })
    return <div className="mt-3">{elements}</div>
  }

  return null
}

const RadioAnswerOption = ({ isSelected, body }: { isSelected: boolean; body: string }) => {
  return (
    <div
      className="p-2 me-2 border-1 border mb-1"
      style={{
        color: isSelected ? 'white' : 'black',
        background: isSelected ? '#6e6e6e' : 'transparent',
      }}
    >
      <p>{body}</p>
    </div>
  )
}

const NprsAnswerOption = ({ isSelected, body }: { isSelected: boolean; body: string }) => {
  return (
    <div
      className="p-2 me-2 border-1 border"
      style={{
        color: isSelected ? 'white' : 'black',
        background: isSelected ? '#6e6e6e' : 'transparent',
      }}
    >
      <p>{body}</p>
    </div>
  )
}
