import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export type ConfigType = {
  countries: {
    abbreviation: string
    countryCode: string
    name: string
  }[]

  languages: {
    name: string
    isoCode: string
    active: boolean
  }[]
  timezones: {
    value: string
    label: string
  }[]
}

export const useConfig = () => {
  const p = ENDPOINTS.PROJECTS.CONFIG
  //
  // notice the use of revaildateOnFocus: false
  //
  const { data, error, mutate } = useSWR(p, getReq, { suspense: true, revalidateOnFocus: false })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  } as { data: ConfigType; isLoading: boolean; isError: any; mutate: any }
}
