import { Button, Empty } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useSubjectAssignments } from '../../hooks/useSubjectAssignments'
import { ListItemContent } from '../../components/display/ListItemContent'
import React from 'react'
import dayjs from 'dayjs'
import { AssignmentType } from '../../Types'

export const SubjectAssignments = () => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }

  const { data } = useSubjectAssignments(projectId, subjectId)

  console.log('SubjectAssignments > result = ', data)
  const hasContent = data ? Object.keys(data).length > 0 : 0
  console.log('SubjectAssignments > hasContent = ', hasContent)

  return (
    <>
      <div className="col-12 mt-3 py-1 mb-3">
        <p className="p-small fw-bolder">ASSIGNMENTS</p>
      </div>

      {Object.keys(data).map((dateKey, index) => {
        const tasks = data[dateKey]
        const d = dayjs(dateKey)
        const startDate = d.format('DD/MM/YY')
        const day = d.format('ddd')

        return (
          <div key={index} className="row" style={{}}>
            <div key={index} className="col-12 mb-1">
              <div className="" style={{ minWidth: 100 }}>
                <div className="mt-4 me-5 rounded-5 py-2 px-3 mb-2">
                  <p className="p-small fw-bold" style={{ color: '#095ada' }}>
                    {day} - {startDate}
                  </p>
                </div>
              </div>
              <div className="w-100" style={{ background: 'red' }}>
                {tasks.map((task: AssignmentType, taskIndex) => {
                  const langKey = Object.keys(task.name)[0]
                  const name = task.name[langKey]

                  return (
                    <div
                      key={taskIndex}
                      className="w-100 bg-white d-flex align-items-center py-2 px-3  "
                      style={{
                        borderBottom: '1px solid',
                        borderColor: '#e8e8e8',
                      }}
                    >
                      <ListItemContent label="Title:" title={name} style={{ width: '35%' }}></ListItemContent>
                      <ListItemContent
                        label="Protocol:"
                        title={'Placeholder'}
                        style={{ width: '25%' }}
                      ></ListItemContent>
                      <ListItemContent
                        label="Progress:"
                        title={task.progress.toString() + '%'}
                        style={{ width: '15%' }}
                      ></ListItemContent>

                      <div className="d-flex justify-content-end" style={{ width: '50%' }}>
                        <Link to={'assignment/' + task._id} className="px-3">
                          <Button type="primary" className="">
                            View
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
      {!hasContent ? <Empty description={'Subject has no recorded answers yet '}></Empty> : null}
    </>
  )
}
