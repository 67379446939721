import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Radio, RadioChangeEvent } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { SetStateAction, useState } from 'react'

import { mutate as mutateGlobal } from 'swr'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { useParams } from 'react-router-dom'

type UnCompleteModalProps = {
  isModalOpen: boolean
  setIsUnCompleteModalOpen: (e: SetStateAction<boolean>) => void
  subject: any
  mutateSubjectData: any
}

export const UnCompleteModal = ({
  isModalOpen,
  setIsUnCompleteModalOpen,
  subject,
  mutateSubjectData,
}: UnCompleteModalProps) => {
  const { projectId } = useParams() as { projectId: string }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)
  const [uncompleteReason, setUncompleteReason] = useState<string>('')
  const [selectedBodyParts, setSelectedBodyParts] = useState<string>()

  const currentPhase = subject && subject.currentPhase ? subject.currentPhase : {}

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const obj = {
      projectId: projectId,
      subjectId: subject._id,
      bodyPart: selectedBodyParts,
      reasonDescription: uncompleteReason,
    }

    return post(ENDPOINTS.SUBJECTS.ACTION.uncomplete(projectId), obj, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateSubjectData()
          setSelectedBodyParts(undefined)
          setIsUnCompleteModalOpen(false)
          setConfirmLoading(false)
          setUncompleteReason('')
          setOpenPop(false)
          mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subject._id))
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setSelectedBodyParts(undefined)
    setIsUnCompleteModalOpen(false)
    setUncompleteReason('')
    setOpenPop(false)
  }

  const handleUnCompleteOk = () => {
    setSelectedBodyParts(undefined)
    setIsUnCompleteModalOpen(false)
    setUncompleteReason('')
    setOpenPop(false)
  }

  const handleUnCompleteCancel = () => {
    setSelectedBodyParts(undefined)
    setIsUnCompleteModalOpen(false)
    setUncompleteReason('')
    setOpenPop(false)
  }

  const cancel = () => {
    setSelectedBodyParts(undefined)
    setIsUnCompleteModalOpen(false)
    setUncompleteReason('')
    setOpenPop(false)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUncompleteReason(e.target.value)
  }

  const onChange = (e: RadioChangeEvent) => {
    setSelectedBodyParts(e.target.value)
  }

  const plainOptions = currentPhase.bodyParts ? currentPhase.bodyParts : []

  const hasReason = uncompleteReason.length < 5 ? false : true

  const canMove = selectedBodyParts !== undefined && hasReason

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <InfoCircleOutlined className="me-2" style={{ color: 'orange', fontSize: '22px' }} />
            Revert Study Completion
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleUnCompleteOk}
        onCancel={handleUnCompleteCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal}>Cancel</Button>
            <Popconfirm
              title="Confirmation: Revert study completion for the subject?"
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button
                className="ms-2"
                type="primary"
                style={{ background: '' }}
                onClick={showPopconfirm}
                disabled={uncompleteReason.length < 5 || plainOptions.length > 0 ? !canMove : false}
              >
                Revert Study Completion
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <div className="row">
          {currentPhase?.bodyParts?.length !== 0 ? (
            <>
              <p className="mt-2">
                You are about to revert the study completion for this subject and re-activate them. This moves the
                subject to their previous phase and thus, you need to select the target body part for the phase below.
              </p>
              <p className="mt-2">
                Please note that the subject's device will now be re-enabled, allowing them to continue the study
                participation and report data for the previous phase.
              </p>
              <div className="mt-2">
                <Radio.Group onChange={onChange} value={selectedBodyParts}>
                  {plainOptions.map((option, index) => {
                    return (
                      <Radio value={option ? option : ''} key={index}>
                        {option ? option : ''}
                      </Radio>
                    )
                  })}
                </Radio.Group>
              </div>
            </>
          ) : (
            <>
              <p className="mt-2">
                You are about to revert the study completion for this subject and re-activate them. This moves the
                subject to their previous phase.
              </p>
              <p className="mt-2">
                Their device will now be re-enabled, allowing them to continue their study participation and report
                data.
              </p>
            </>
          )}
        </div>
        <div>
          <p className="mt-2">Please provide a written reason for the revert action for the Audit Trail.</p>
          <TextArea placeholder="" rows={2} className="mt-2" onChange={handleTextChange} />
        </div>
      </Modal>
    </>
  )
}
