import { Button, Table, TableColumnsType } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMeSites } from '../../hooks/useMeSites'
import { getReq } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'

type DataType = {
  key: React.Key
  name: string
  age: number
  address: string
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Site number',
    dataIndex: 'siteNumber',
  },
  {
    title: 'Site name',
    dataIndex: 'title',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Timezone',
    dataIndex: 'timeZone',
  },
]

export const DMView = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data: sites } = useMeSites(projectId)

  const [siteData, setSiteData] = useState([])
  const [selectedSites, setSelectedSites] = useState<React.Key[]>([])
  const [loadingAudit, setLoadingAudit] = useState(false)
  const [loadingAssignments, setLoadingAssignments] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setSiteData(buildSiteTable(sites))
  }, [sites])

  //
  // Antd table needs a key to be unique and since we do not have a key coming from the backend
  // we set the key to be the _id
  //
  const buildSiteTable = (sites) => {
    return sites.map((site) => {
      return {
        ...site,
        key: site._id,
      }
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setShowError(false)
      setSelectedSites(selectedRowKeys)
    },
  }

  const exportSelected = () => {
    if (selectedSites.length > 0) {
      setLoadingAssignments(true)

      setShowError(false)

      const p = ENDPOINTS.SUBJECTS.exportSelectedAssignments(projectId, selectedSites as string[])

      return getReq(p)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result]))
          const link = document.createElement('a')
          link.href = url

          const today = new Date()
          const yyyy = today.getFullYear()
          let mm = today.getMonth() + 1
          let dd = today.getDate()

          const formattedToday = dd + '/' + mm + '/' + yyyy

          const fileName = 'subjectsAssignments-' + formattedToday + '.csv'
          link.setAttribute('download', fileName)

          document.body.appendChild(link)
          link.click()

          // Clean up and remove the link
          link.parentNode?.removeChild(link)

          setLoadingAssignments(false)
        })
        .catch((error) => {
          alert(error.message)
          setLoadingAssignments(false)
        })
    } else {
      setShowError(true)
    }
  }

  const exportAudits = () => {
    setLoadingAudit(true)

    setShowError(false)

    const p = ENDPOINTS.SUBJECTS.exportSubjectAudits(projectId)

    return getReq(p)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a')
        link.href = url

        const today = new Date()
        const yyyy = today.getFullYear()
        let mm = today.getMonth() + 1
        let dd = today.getDate()

        const formattedToday = dd + '/' + mm + '/' + yyyy

        const fileName = 'subjectsAudits-' + formattedToday + '.csv'
        link.setAttribute('download', fileName)

        document.body.appendChild(link)
        link.click()

        // Clean up and remove the link
        link.parentNode?.removeChild(link)

        setLoadingAudit(false)
      })
      .catch((error) => {
        alert(error.message)
        setLoadingAudit(false)
      })
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 py-3">
          <h4>Data management</h4>
        </div>
        <div className="col-12 mb-2">
          <p className="p-small w-75">
            Effortlessly download and manage subject data across sites to ensure data integrity and validity for
            insightful analysis.
          </p>
        </div>
      </div>
      <div className="row mb-3 mt-3">
        <div className="col-12">
          <div
            className="h-100 px-4 py-4 rounded-1"
            style={{
              boxShadow: '0px 3px 4px -3px rgba(0, 0, 0, .4)',
              background: 'white',
            }}
          >
            <div className="col-12 row">
              <div className="col-8">
                <h5 className="" style={{ color: 'black' }}>
                  Subject Assignment Export
                </h5>
                <p className="p-small" style={{ color: 'black' }}>
                  Choose the sites to export subject assignments and data from.
                </p>
              </div>
              <div className="col-4">
                <div className="d-flex justify-content-end">
                  <Button className="my-3" type="primary" loading={loadingAssignments} onClick={exportSelected}>
                    Download assignments
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <Table
                bordered
                size="large"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={siteData}
                pagination={false}
              />
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  {selectedSites.length === 0 && showError ? (
                    <div className="mt-3 p-small text-danger mb-2 d-flex justify-content-start">
                      Data cannot be exported without site selection
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div
            className="h-100 px-4 py-4 rounded-1"
            style={{
              boxShadow: '0px 3px 4px -3px rgba(0, 0, 0, .4)',
              background: 'white',
            }}
          >
            <div className="col-12 row">
              <div className="col-8">
                <h5 className="" style={{ color: 'black' }}>
                  Subjects Audit Export
                </h5>
                <p className="p-small" style={{ color: 'black' }}>
                  Enhance your Subject Assignment Exports by downloading the associated audit activity log for all
                  subjects.
                </p>
              </div>
              <div className="col-4">
                <div className="d-flex justify-content-end">
                  <Button className="my-2" type="primary" loading={loadingAudit} onClick={exportAudits}>
                    Download subjects audit log
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
