/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/05/2024.
 */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { Button, Modal } from 'antd'
import { FormLabel } from '../../components/form/FormLabel'
import TextArea from 'antd/es/input/TextArea'

type Props = { show: boolean; setShow: (value: boolean) => void; deviceId: string; mutateSubjectData: () => void }

export const DeviceWipeModal = ({ show, setShow, deviceId, mutateSubjectData }: Props) => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }
  const [justification, setJustification] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //setDisqualificationReason(e.target.value)

    if (error.length > 0) {
      setError('')
    }

    setJustification(e.target.value)
  }

  const cancel = () => {
    if (isLoading) {
      return
    }
    setShow(false)
  }

  const confirmWipe = () => {
    if (isLoading) {
      return
    }

    if (!justification || justification.length < 3) {
      setError('Please provide a justification for this action')
      return null
    }

    setIsLoading(true)
    const obj = {
      projectId: projectId,
      subjectId: subjectId,
      deviceId: deviceId,
      reason: justification,
    }

    const path = ENDPOINTS.SUBJECTS.wipeDevice(projectId)
    post(path, obj)
      .then((res) => {
        setIsLoading(false)
        console.log('DeviceDetails > res = ', res)
        mutateSubjectData()
        setShow(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('DeviceDetails > err = ', err)
        alert(err.message)
      })
  }

  return (
    <Modal
      title={
        <h4 key={0} className="my-2">
          Wipe device
        </h4>
      }
      open={show}
      onCancel={cancel}
      destroyOnClose={true}
      width={600}
      footer={[
        <div key={1}>
          <Button className="ms-2" type="default" onClick={cancel}>
            Cancel
          </Button>
          <Button className="ms-2" danger type="primary" onClick={confirmWipe} loading={isLoading}>
            Wipe device
          </Button>
        </div>,
      ]}
    >
      <div className="mt-3">
        You are about to initiate a device replacement action for your subject. This action will restrict the
        participant's access to the current device.
        <p className="mt-2">
          Once deactivated, the participant will need a new device assigned to them to continue participating in the
          clinical trial.
        </p>
        <p className="mt-2">
          Please note that all submitted data is securely stored in the cloud, ensuring the participants can continue
          from where they left off.
        </p>
      </div>
      <div className="mt-3">
        <FormLabel label="Provide justification"></FormLabel>
        <TextArea placeholder="Reactivation reason" rows={2} className="" onChange={handleTextChange} />
        {error ? <p className="text-danger">{error}</p> : null}
      </div>
    </Modal>
  )
}
