/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10/12/2021.
 */
import React, { useState } from 'react'
import { authStore } from '../../store/auth/authStore'
import { Auth } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_FORGOT_PASSWORD, LOGIN_MFA_CODE } from './PAGES_LOGIN'

export const CompleteNewPassword = () => {
  const user = authStore((state) => state.user)
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  //const [phoneNumber, setPhoneNumber] = useState('')
  //const [countryCode, setCountryCode] = useState('')
  const init: any = authStore((state) => state.init)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    // Check password match
    if (passwordOne !== passwordTwo) {
      setError('The passwords entered do not match')
      return
    }

    /*const requiredAtt = {
      phone_number: countryCode + phoneNumber,
    }*/

    Auth.completeNewPassword(
      user, // the Cognito User Object
      passwordOne
      //requiredAtt // the new password
    )
      .then((user) => {
        // at this time the user is logged in if no MFA required
        if (!user.challengeName) {
          init().then((result) => {
            navigate('/', { replace: true })
          })
        }
        if (user.challengeName === 'SMS_MFA') {
          navigate(LOGIN_MFA_CODE.to, { replace: true })
          return
        }

        console.log(user)
      })
      .catch((e) => {
        setError(e.message)
        console.log(e)
      })
  }

  /*  const pnoneNumberRequired =
    user?.challengeParam && user?.challengeParam?.requiredAttributes?.indexOf('phone_number') > -1 ? true : false*/

  /*const countryChange = (v) => {

    setCountryCode(v)
  }*/

  return (
    <>
      <h3 className="font-weight-bold text-center mb-4">Enter a new password</h3>
      <form className="login-page" onSubmit={handleFormSubmit}>
        <div className="w-100 ">
          <label htmlFor="username">Password</label>
          <input
            id="new-password"
            type="password"
            placeholder="New password"
            autoComplete="new-password"
            value={passwordOne}
            name="passwordOne"
            onChange={(e) => setPasswordOne(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div>
          <label htmlFor="new-password">Repeat Password</label>
          <input
            id="new-password"
            type="password"
            autoComplete="new-password"
            placeholder="Repeat password"
            value={passwordTwo}
            name="passwordTwo"
            onChange={(e) => setPasswordTwo(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />

          {/* <div className="d-flex">
            <div className="" style={{ width: 90 }}>
              <label htmlFor="country">Country code</label>
              <Select
                size="large"
                className="mt-1 pe-1 w-100"
                defaultValue="-"
                onChange={countryChange}
                optionLabelProp="label"
                popupMatchSelectWidth={300}
              >
                {SUPPORTED_COUNTRIES.map((item, index) => {
                  return (
                    <Option key={index} label={item.countryCode} value={item.countryCode}>
                      <p>
                        {item.countryCode} <span className="opacity-75">{item.name}</span>
                      </p>
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className="">
              <label htmlFor="phone_number">Your phone number</label>
              <input
                id="phone_number"
                type="text"
                placeholder="phone number"
                value={phoneNumber}
                name="phone_number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{ borderRadius: 4, height: 40 }}
              />
            </div>
          </div>*/}
          {/*<span>Phone number should include country code</span>*/}

          <div className=" ">
            <input
              style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
              type="submit"
              value="Login"
            />
            <p className="mt-1 mb-2 text-center text-danger">{error}</p>
          </div>
          <div className="text-center">
            <Link to="/login">
              <p className="mb-2">Back to login</p>
            </Link>
            <Link to={LOGIN_FORGOT_PASSWORD.to}>Request new code</Link>
          </div>
        </div>
      </form>
    </>
  )
}
