/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { Button } from 'antd'
import Alert from 'antd/lib/alert'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CRUD_VIEW_TYPE, SiteType } from '../../Types'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { SideBarAddSites } from './SideBarAddSites'
import { SiteBarSiteList } from './SideBarSiteList'
import { useClinicianById } from '../../hooks/useClinicianById'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { openNotification } from '../../components/notificationToast'
import { useCliniciansByProjectId } from '../../hooks/useCliniciansByProjectId'
import { useForm } from 'react-hook-form'
import { FormJustification } from '../../components/form/FormJustification'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: CRUD_VIEW_TYPE
  me: any
}

export const ClinicianSiteCRUD = ({ onClose, closeDrawer, viewType = CRUD_VIEW_TYPE.CREATE, me }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data, mutate } = useClinicianById(projectId, userId)

  const { mutate: listMutate } = useCliniciansByProjectId(projectId)

  const formHook = useForm()

  const { handleSubmit } = formHook

  const [errorState, setErrorState] = useState<any>()
  const [showAddSiteDrawer, setAddSiteDrawer] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const initSites = useMemo(() => {
    const defaultSiteArr = data?.sites || []
    return viewType === CRUD_VIEW_TYPE.CREATE ? [] : defaultSiteArr
  }, [viewType, data])

  const [selectedSites, setSelectedSites] = useState<SiteType[]>(initSites)

  useEffect(() => {
    const initSites = data?.sites || []
    const len = initSites.filter((site, index) => selectedSites[index]?._id === site._id).length

    // it is dirty if the length of the selected sites is different from the initial sites
    // if the length is the same, we need to check if the selected sites are the same as the initial sites
    const isDirty = len !== initSites.length || selectedSites.length !== initSites.length
    setIsDirty(isDirty)
  }, [selectedSites, data?.sites])

  const onSubmit = (formData) => {
    const justification = formData.justification
    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'

    const updatedSendData = {
      staffId: userId,
      sites: selectedSites,
      projectId: projectId,
      justification: justification,
    }

    const path = ENDPOINTS.CLINICIANS.ACTION.sites(projectId)
    setIsLoading(true)
    return post(path, updatedSendData, method)
      .then(() => {
        const title = viewType === CRUD_VIEW_TYPE.UPDATE ? 'Update successfully' : 'Created successfully'
        const msg = viewType === CRUD_VIEW_TYPE.UPDATE ? 'Clinician has been updated' : 'Clinician has been created'

        openNotification(title, msg, 'happy')
        setIsLoading(false)
        mutate()
        listMutate()

        if (onClose) {
          onClose()
        }

        if (closeDrawer) {
          closeDrawer(false)
          setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorState(error)
      })
  }

  const updateSelectedSites = (theSites) => {
    setSelectedSites(theSites)
  }

  const removeSelectedSite = (id) => {
    const updatedSites: SiteType[] = selectedSites.filter((item) => {
      return item._id !== id
    })

    setSelectedSites(updatedSites)
  }

  const open = () => {
    setAddSiteDrawer(true)
  }

  const closeAddSiteDrawer = () => {
    setAddSiteDrawer(false)
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  return (
    <>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="px-3">
            <p className="mb-1 opacity-75">Selected clinician:</p>
            <h5 className="fw-bold text-truncate">{data ? data.email : '-'}</h5>
            <p>
              Please select the site, the user needs access to. When assigning sites to users, please ensure, they are
              given access only to the specific sites required for their tasks to ensure compliance.
            </p>
            <p className="mt-2">
              Access to sites can be revoked at any time for necessary changes. Please provide a written reason for the
              Audit Trail.
            </p>
          </div>
          <div className="col-12 mb-4 mt-4">
            <SiteBarSiteList
              open={open}
              selectedSites={selectedSites}
              removeSelectedSite={removeSelectedSite}
              viewType={CRUD_VIEW_TYPE.CREATE}
            ></SiteBarSiteList>
          </div>

          {isDirty ? (
            <div className="col-12 mb-3 ">
              {/*Justification*/}
              <FormJustification formHook={formHook} />
              {/*<div className="px-3 pt-3 pb-4 rounded-2" style={{ background: '#f3f3f3' }}>
            {isDirty ? <JustificationInput formHook={formHook} /> : null}
          </div>*/}
            </div>
          ) : null}
          <div className="col-12 d-flex justify-content-end mt-3">
            <Button onClick={handleSubmit(onSubmit, onError)} type="primary" loading={isLoading} disabled={!isDirty}>
              {viewType === CRUD_VIEW_TYPE.CREATE ? 'Create user' : 'Save changes'}
            </Button>
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
        </form>
      ) : (
        <div className="">
          <h1>not used</h1>
        </div>
      )}
      {showAddSiteDrawer ? (
        <DrawerContainer setVisible={closeAddSiteDrawer} projectId={''} width={550} to="." title="Site list">
          <SideBarAddSites projectId={projectId} setSelectedSites={updateSelectedSites} selectedSites={selectedSites} />
        </DrawerContainer>
      ) : (
        <></>
      )}
    </>
  )
}
