import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { getReq } from '../../service/API'
import Spinner from '../../components/spinner/Spinner'
import { CheckCircleOutlined } from '@ant-design/icons'
import useSWR from 'swr'
import { Button } from 'antd'

export const SubjectOnboardPage = () => {
  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }

  const p = ENDPOINTS.SUBJECTS.qr(projectId, subjectId)

  const [QrScanned, setQrScanned] = useState<boolean>(false)
  const [qrCode, setQRCode] = useState(null)

  const interval = !QrScanned ? 1000 : 0

  const { data, error } = useSWR(p, getReq, { refreshInterval: interval })

  useEffect(() => {
    if (data?.data?.qrCode && !data?.data?.QR_SCANNED) {
      setQRCode(data?.data?.qrCode)
      setQrScanned(false)
    }

    console.log('SubjectOnboardPage > data = ', data?.data)
    if (data?.data && data?.data?.QR_SCANNED) {
      setQRCode(null)
      setQrScanned(true)
    }

    if (error) {
      alert(JSON.stringify(error))
    }
  }, [data?.data?.QR_SCANNED, data?.data?.qrCode, data?.data, error])

  console.log('SubjectOnboardPage > QrScanned = ', QrScanned)
  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 mt-5 ms-6">
          <h1>Assign a device</h1>
          <p>Please follow the steps described below:</p>
        </div>
      </div>

      <div className="row" style={{}}>
        {!QrScanned ? (
          <div className="col-12 mt-5 d-flex justify-content-center">
            {qrCode ? (
              <img src={qrCode} alt="QR Code" style={{ width: 200, height: 200 }}></img>
            ) : (
              <div
                className="bg-white d-flex align-items-center justify-content-center"
                style={{ width: 200, height: 200 }}
              >
                <Spinner size={10} style={{ marginTop: '-50px' }}></Spinner>
              </div>
            )}
          </div>
        ) : null}

        {QrScanned === true ? (
          <div className="col-5 p-6 mx-auto mt-5 d-flex justify-content-center bg-white">
            <div className=" d-flex align-items-center justify-content-center flex-column ">
              <h3 className="text-center">Device Assignment Complete</h3>

              <div className="d-flex justify-content-center my-3">
                <CheckCircleOutlined style={{ color: '#1aa234', fontSize: '4rem' }} />
              </div>

              <div className="mt-3 ">
                <h5 className="text-center">QR code was scanned successfully</h5>
                <p className="mt-3 text-center">
                  Subject has been successfully assigned a device and will now appear in the Subjects view which can be
                  accessed via the menu to the left.
                </p>
                <div className="d-flex justify-content-center mt-4">
                  {/* site/65548f1178520acc0e189200/subject/view/65c3660d7e4e8b44bac9b3c4*/}
                  <Link to={`../view/${subjectId}`}>
                    <Button type="primary">View Subject</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {QrScanned === false ? (
        <div className="row" style={{}}>
          <div className="col-12 mt-5 ms-6">
            <p>Device assignment process:</p>
            <p>
              <b>Step 1:</b> Turn on the device and tap on the ANNEA application.
            </p>
            <p>
              <b>Step 2:</b> Enable camera and calendar access on the device.
            </p>
            <p>
              <b>Step 3:</b> Scan the displayed QR code in the portal using the camera on the device.
            </p>
            <p>
              <b>Step 4:</b> Hand over the device to the subject for creating a 4-digit PIN code.
            </p>
            <p>
              <b>Step 5:</b> The subject confirms the PIN code.
            </p>
            <p>
              <b>Step 6:</b> Instruct the subject to complete the onboarding flow.
            </p>
            <p className="mt-2">
              &#42;If the QR code scan encounters an error, retry by navigating to 'Subjects &#62; View Subject.' If the
              issue persists, try another device.
            </p>
          </div>
        </div>
      ) : null}
    </div>
  )
}
