/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */

import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'

export const FormDropDown = ({ data, formHook }) => {
  const {
    setValue,
    register,
    formState: { errors },
    getValues,
  } = formHook

  const errorState = errors[data.id]

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value
  const [state, setState] = useState<string | null>(currentValue)

  useEffect(() => {
    register(data.id, data.validation)

    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [register, data.id, data.validation, data.value, setValue])

  const handleChange = (value) => {
    setValue(data.id, value)
    setState(value)
  }

  return (
    <div className="py-1">
      {/*<FormLabel label={data.label} />*/}
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <Select
        defaultValue={state}
        value={currentValue}
        style={{ width: '100%' }}
        onChange={handleChange}
        placeholder={data.placeholder}
        options={data.options}
        status={errorState ? 'error' : ''}
      ></Select>

      {errorState ? <div className="p-small text-danger">{data.errorMsg}</div> : null}
    </div>
  )
}
