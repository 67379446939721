import { Alert, Button, Steps } from 'antd'
import React, { useState } from 'react'
import { a, useTransition } from '@react-spring/web'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { getReq, post } from '../../service/API'
import { useNavigate, useParams } from 'react-router-dom'
import { useErrorBoundary } from 'react-error-boundary'
import { useForm } from 'react-hook-form'
import { CRUD_VIEW_TYPE } from '../../Types'
import { FormTextField } from '../../components/form/FormTextField'
import { UserPii } from './UserPii'
import { Confirmation } from './Confirmation'
import { mutate as mutateGlobal } from 'swr'

const data = {
  id: 'screeningId',
  type: 'TEXT',
  placeholder: '',
  label: 'Screening ID',
  prefix: '',
  postfix: '',
  value: '',
  validation: { required: true },
  errorMsg: 'This field is required',
  defaultValue: '',
  disabled: false,
  className: '',
}

export const SubjectCreatePage = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const [current, setCurrent] = useState(0)

  const navigate = useNavigate()

  const transitions = useTransition(current, {
    from: { transform: 'translate3d(20%,0,0)', opacity: 0 },
    enter: { transform: 'translate3d(0%,0,0)', opacity: 1 },
    leave: { transform: 'translate3d(-25%,0,0)', opacity: 0 },
  })

  const formHook = useForm({ defaultValues: { screeningId: '' } })

  const { handleSubmit } = formHook

  const submitWrapper = (e) => {
    e.preventDefault() // Prevent form submit by pressing enter key
    handleSubmit(onSubmit, onError)
  }

  const onError = (err, e) => {
    e.preventDefault()
    //showBoundary(err)
    alert(err)
    console.log('err = ', err)
  }

  const { showBoundary } = useErrorBoundary()

  const onSubmit = (formData, e) => {
    const obj = { ...formData, siteId: siteId, projectId: projectId }

    const path = ENDPOINTS.SUBJECTS.ACTION.create(projectId)
    post(path, obj)
      .then((result) => {
        mutateGlobal(ENDPOINTS.SUBJECTS.get(projectId, siteId))
        console.log('SubjectCreatePage > result = ', result)
        navigate(`../onboard/${result.data}`)

        return result
      })
      .catch((error) => {
        console.log('SubjectCreatePage > error = ', error)
        showBoundary(error)
        return Promise.reject(error)
      })
  }

  const steps = [
    {
      title: 'Initiate',
      content: <Start formHook={formHook} nextIndex={1} setPageIndex={setCurrent} projectId={projectId} />,
    },
    {
      title: 'Subject details',
      content: <UserPii formHook={formHook} prevIndex={0} nextIndex={2} setPageIndex={setCurrent} />,
    },
    {
      title: 'Confirmation',
      content: (
        <Confirmation
          formHook={formHook}
          prevIndex={1}
          nextIndex={3}
          setPageIndex={setCurrent}
          onSubmit={handleSubmit(onSubmit, onError)}
        />
      ),
    },
  ]

  const items = steps.map((item) => ({ key: item.title, title: item.title }))

  return (
    <div className="container mt-6 ">
      <div className="row" style={{}}>
        <div className="col-8 mx-auto d-flex justify-content-center">
          <h4>Create a subject</h4>
        </div>
      </div>

      <div className="row" style={{}}>
        <div className="col-8 mx-auto d-flex justify-content-center mt-2">
          <p>Create a subject as an ePRO user by following the below three steps.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-6 d-flex justify-content-center">
          <Steps className="col-8" current={current} items={items} />
        </div>

        <div className="col-12 position-relative h-100">
          {transitions((style, item) => (
            <a.div className="position-absolute w-100 h-100 " style={style}>
              <form className="row" onSubmit={submitWrapper}>
                {steps[item].content}
              </form>
            </a.div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Start = ({ formHook, nextIndex, setPageIndex, projectId }) => {
  const { trigger, getValues } = formHook
  const [errorState, setErrorState] = useState<any>()

  const next = async () => {
    const isValid = await trigger(data.id)
    const screeningId = getValues(data.id)

    if (!screeningId) return

    // Check if a subject with screening ID is already existing
    const p = ENDPOINTS.SUBJECTS.getSubjectValidateScreeningId(projectId, screeningId)
    getReq(p)
      // Entering here, the screening ID can be used
      .then((result) => {
        if (isValid) {
          setPageIndex(nextIndex)
        }
      })
      // The screening ID is already in use
      .catch((error) => {
        console.log(' > error = ', error)
        setErrorState(error)
      })
  }

  return (
    <div className="row ">
      <div className="col-6 mx-auto mt-6 ">
        <div className="bg-white border block-shadow p-5">
          <h5 className="">Please enter the screening ID</h5>
          <FormTextField data={data} formHook={formHook} viewType={CRUD_VIEW_TYPE.UPDATE}></FormTextField>
          <p className="p-small opacity-75" style={{ fontStyle: 'italic' }}>
            (Unique identifier for the subject participating in the clinical trial)
          </p>
          {errorState && (
            <Alert className="mt-3" showIcon message={errorState.type} description={errorState.message} type="error" />
          )}
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-4">
        <Button disabled={true} className="mx-1" type="default">
          Back
        </Button>
        <Button className="mx-1" type="primary" onClick={next}>
          Continue
        </Button>
      </div>
    </div>
  )
}
