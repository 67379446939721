import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { TRIAL_STAFF_TYPE_ENUM, USER_TYPES, UserTypes } from '../../Types'

type Props = {
  userType: UserTypes
  staffType?: TRIAL_STAFF_TYPE_ENUM
}

export const HideEdit = ({ userType, staffType }: Props) => {
  return (
    <>
      {userType === USER_TYPES.CTM ? (
        <>
          {staffType === TRIAL_STAFF_TYPE_ENUM.CRA ? (
            <Link to="sites">
              <Button className="me-3" type="primary">
                {'Edit site access'}
              </Button>
            </Link>
          ) : null}

          <Link to="edit">
            <Button type="primary">{'Edit information'}</Button>
          </Link>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
