import { CRUD_VIEW_TYPE, TRIAL_STAFF_TYPE_ENUM } from '../../Types'

export const getTrialStaff = () => {
  return trialConfig
}

const trialConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add user name',
    label: 'Name',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add user email',
    label: 'Email',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
    disabled: { [CRUD_VIEW_TYPE.CREATE]: false, [CRUD_VIEW_TYPE.UPDATE]: true, [CRUD_VIEW_TYPE.VIEW]: true },
  },
  {
    id: 'phone',
    type: 'PHONE',
    placeholder: 'Add investigator phone number',
    label: 'Phone number',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Phone number required',
    disabled: false,
  },
  {
    id: 'type',
    type: 'DROPDOWN',
    placeholder: 'Select manager type',
    label: 'Trial staff type',
    prefix: '',
    postfix: '*',
    value: '',
    options: [
      { value: TRIAL_STAFF_TYPE_ENUM.CTM, label: 'CTM - Clinical Trial Manager' },
      { value: TRIAL_STAFF_TYPE_ENUM.CRA, label: 'CRA - Clinical Research Associate' },
      { value: TRIAL_STAFF_TYPE_ENUM.DM, label: 'DM - Data Manager' },
    ],
    validation: { required: true },
    errorMsg: 'A type must be selected',
    disabled: true,
  },
  {
    id: 'jobTitle',
    type: 'TEXT',
    placeholder: 'Add job title (optional)',
    label: 'Job title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
  },
]
