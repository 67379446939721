/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/05/2023.
 */
import { Button } from 'antd'
import React, { Suspense } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { SubpageNavigator } from '../../components/navigator/SubpageNavigator'
import { DeviceDetails } from './DeviceDetails'
import { SubjectAuditTrial } from './SubjectAuditTrial'

import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import Spinner from '../../components/spinner/Spinner'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { authStore } from '../../store/auth/authStore'
import { SubjectAssignment } from './SubjectAssignment'
import { SubjectAssignments } from './SubjectAssignments'
import { SubjectPii } from './SubjectPII'
import { SubjectUpdateWrapper } from './subject_update/SubjectUpdateWrapper'
import { useSubject } from '../../hooks/useSubject'
import { useProjectById } from '../../hooks/useProjectById'
import { PROJECT_STATES } from '../../Types'
import { SubjectDetailsView } from './SubjectDetailsView'

export const SubjectView = () => {
  const { projectId, subjectId } = useParams() as { subjectId: string; projectId: string }
  const userAtt = authStore((state) => state.user?.attributes)

  const { data: subjectData } = useSubject(projectId, subjectId)
  const { data: projectData } = useProjectById(projectId)

  const subpageMenu = [`Overview`, 'Device', 'Audit trail', 'Personal Information']

  const closeDrawer = () => {}

  const showUpdateBtn = userAtt?.['custom:userType'] !== 'CLINICIAN' || projectData.status !== PROJECT_STATES.ACTIVE

  return (
    <>
      <div className="container-fluid bg-white ">
        <div className="container pt-5 pb-4 px-lg-6">
          <SubjectDetailsView
            subjectData={subjectData}
            child={
              !showUpdateBtn ? (
                <div className="">
                  <Link to={'./update'}>
                    <Button
                      className=""
                      type="primary"
                      disabled={
                        userAtt?.['custom:userType'] !== 'CLINICIAN' || projectData.status !== PROJECT_STATES.ACTIVE
                      }
                    >
                      Update Subject Status
                    </Button>
                  </Link>
                </div>
              ) : null
            }
          ></SubjectDetailsView>
        </div>
      </div>

      <SubpageNavigator menu={subpageMenu}>
        <SubjectOverview></SubjectOverview>
        <DeviceDetails></DeviceDetails>
        <Suspense fallback={<Spinner size={10}></Spinner>}>
          <SubjectAuditTrial></SubjectAuditTrial>
        </Suspense>
        <Suspense fallback={<Spinner></Spinner>}>
          <SubjectPii me={userAtt}></SubjectPii>
        </Suspense>
      </SubpageNavigator>

      <Routes>
        <Route
          path="/update"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer
                projectId={projectId}
                setVisible={closeDrawer}
                width={300}
                to="../"
                title="Update Subject Status"
              >
                <SubjectUpdateWrapper />
              </DrawerContainer>
            </Suspense>
          }
        />
        <Route
          path="/assignment/:assignmentId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer
                projectId={projectId}
                setVisible={closeDrawer}
                width={'50%'}
                to="../"
                title="Subject answers"
              >
                <SubjectAssignment></SubjectAssignment>
              </DrawerContainer>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}

const SubjectOverview = () => {
  return (
    <div className="container">
      <div className="row" style={{}}>
        <Suspense fallback={<SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>}>
          <SubjectAssignments />
        </Suspense>
      </div>
    </div>
  )
}
