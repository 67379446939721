import React, { useEffect } from 'react'
import { Input } from 'antd'
import { FormLabel } from './FormLabel'

type JustificationProps = {
  formHook: any
  label?: string
  id?: string
}
export const JustificationInput = ({
  formHook,
  label = 'Justification for change',
  id = 'justification',
}: JustificationProps) => {
  const { TextArea } = Input
  const {
    register,
    setValue,
    unregister,
    formState: { errors },
  } = formHook

  const errorMsg = 'Please provide a reason'
  const errorState = errors[id]
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : errorMsg

  useEffect(() => {
    register(id, { required: true })

    return () => {
      unregister(id)
    }
  }, [register, unregister, id])

  const onChangeHandler = (val: string) => {
    setValue(id, val)
  }

  return (
    <div>
      <FormLabel label={label} />
      <TextArea
        id={id}
        rows={3}
        placeholder=""
        onChange={(e) => onChangeHandler(e.target.value)}
        defaultValue=""
        showCount
        maxLength={100}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
