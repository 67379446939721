/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10/12/2021.
 */
import React, { useState } from 'react'
import { authStore } from '../../store/auth/authStore'
import { LOGIN_FORGOT_PASSWORD } from './PAGES_LOGIN'
import { Link, useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const ForgotPasswordSubmit = () => {
  const navigate = useNavigate()
  const forgotPasswordSubmit = authStore((state) => state.forgotPasswordSubmit)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')

  const [error, setError] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    if (!email || !code || !password) {
      alert('please fill in all fíelds')

      return ''
    }

    forgotPasswordSubmit(
      email,
      code,
      password // the new password
    )
      .then((result) => {
        // at this time the result is logged in if no MFA required
        if (result === 'SUCCESS') {
          //setNavigateTo(LOGIN.to)
          navigate('/', { replace: true })
        }
        console.log(' ForgotPasswordSubmit > result = ', result)

        setError(result.toString())
      })
      .catch((e) => {
        console.log(' ForgotPasswordSubmit > e = ', e)
        setError(e.message)
      })
  }

  /*if (navigateTo !== '') {
		return (
			<Redirect
				to={{
					pathname: navigateTo,
					//state: { user: userResponse },
				}}
			></Redirect>
		)
	}*/

  return (
    <>
      <h4 className="fw-bold text-center upp mb-3">Reset Password</h4>
      <form className="login-page" onSubmit={handleFormSubmit}>
        <div className=" ">
          <label htmlFor="code">Verification code from email or SMS</label>
          <input
            id="code"
            type="text"
            placeholder="Code from email or text"
            autoComplete="code"
            value={code}
            name="code"
            onChange={(e) => setCode(e.target.value)}
            className="w-100"
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div className="w-100 ">
          <label htmlFor="username">Your email</label>
          <input
            id="email"
            type="text"
            placeholder="Email"
            autoComplete="email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            className="w-100"
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div>
          <label htmlFor="password">New Password</label>
          <input
            id="new-password"
            type="password"
            autoComplete="new-password"
            placeholder="Password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
          <div className=" ">
            <input
              style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
              type="submit"
              value="Login"
            />

            <p className="mt-1 text-center text-danger">{error}</p>

            {/*<div className="d-flex justify-content-center ">*/}
            {/*  <Link to={LOGIN_FORGOT_PASSWORD.to}>Request new code</Link>*/}
            {/*</div>*/}

            <div className="d-flex justify-content-center align-items-center">
              <Link to={LOGIN_FORGOT_PASSWORD.to}>Request new code</Link>

              <div className="ms-2" style={{ marginTop: '4px' }}>
                <Tooltip
                  placement="bottom"
                  title={'Click on the link to request a new verification code if your previous code has expired'}
                  arrow={true}
                >
                  <QuestionCircleOutlined style={{ color: '#000' }} />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="bg-white p-4 mt-5 shadow w-100" style={{ maxWidth: 375 }}>
        <div className="opacity-75 p-small px-1">
          <div className="">
            <h6>
              <b>Password Policy:</b>
            </h6>
          </div>
          <div className="">
            <p className="mt-1">
              <span style={{ fontWeight: 600 }}>Minimum password length:</span> At least 8 characters.
            </p>
            <p className="mt-1">
              <span style={{ fontWeight: 600 }}>Complexity requirements:</span> Include a mix of uppercase and lowercase
              letters, numbers, and special characters.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
