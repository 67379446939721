//
// ENV variables are set in .env file
//
export const BASE_URL = process.env.REACT_APP_BASE_URL

export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false

export const VERSION = process.env.REACT_APP_CURRENT_VERSION || '0.0.0'

/**
 *
 * Handle inactivity timeout
 *
 * @inactivityInSeconds seconds before the user is logged out
 * @logoutAfterSeconds amount of seconds before the modal is shown
 *
 * @example
 * inactivityInSeconds = 1000 , logoutAfterSeconds = 500 means the modal will be show after 500 seconds and will be logged out after 1000 seconds
 *
 */
export const inactivityInSeconds = 300 // 5 minutes before logout, popup happens
export const logoutAfterSeconds = 1800 // 30 minutes - when logout happens

export const PORTAL_API_URL = BASE_URL + '/api/portal'

export const TRIAL_STAFF = 'TRIAL_STAFF'
export const CLINICIAN = 'CLINICIAN'

export const COLOR_PRIMARY = '#005ff1' //'#007bff'

export const LANGUAGE_LABELS = {
  en: 'English',
  es: 'Spanish',
  da: 'Danish',
  yue: 'Cantonese',
}

export const defaultEmailAddress = 'default@email.com'
export const defaultPhoneNumber = 1111111111
export const defaultCountryCode = '+1'

export const BODY_PARTS = {
  ABDOMEN_LEFT: 'Left abdomen',
  ABDOMEN_RIGHT: 'Right abdomen',
  ABDOMEN_UPPER: 'Upper abdomen',
  ABDOMEN_LOWER: 'Lower abdomen',
  ANKLE_LEFT: 'Left ankle',
  ANKLE_RIGHT: 'Right ankle',
  ARM_LEFT: 'Left arm',
  ARM_RIGHT: 'Right arm',
  BACK: 'Back',
  FOOT_LEFT: 'Left foot',
  FOOT_RIGHT: 'Right foot',
  HAND_LEFT: 'Left hand',
  HAND_RIGHT: 'Right hand',
  HEAD: 'Head',
  HIP_LEFT: 'Left hip',
  HIP_RIGHT: 'Right hip',
  KNEE_LEFT: 'Left knee',
  KNEE_RIGHT: 'Right knee',
  INDEX_KNEE: 'Index knee',
  LEG_LEFT: 'Left leg',
  LEG_RIGHT: 'Right leg',
  SHOULDER_LEFT: 'Left shoulder',
  SHOULDER_RIGHT: 'Right shoulder',
  WRIST_LEFT: 'Left wrist',
  WRIST_RIGHT: 'Right wrist',
} as const

// COLOR

export const COLOR = {
  LIGHT_GRAY: '#f3f3f3',
}
