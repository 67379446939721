import React from 'react'

import LoginForm from './LoginForm'

import { Route, Routes } from 'react-router-dom'
import { LOGIN_FORGOT_PASSWORD, LOGIN_FORGOT_PASSWORD_SUBMIT, LOGIN_MFA_CODE, LOGIN_NEW_PASSWORD } from './PAGES_LOGIN'
import { ForgotPasswordSubmit } from './ForgotPasswordSubmit'
import { LoginMFA } from './LoginMFA'
import { ForgotPassword } from './ForgotPassword'
import { CompleteNewPassword } from './CompleteNewPassword'
import { LoginBackground } from './LoginBackground'

import './login-page.scss'
import bgImage from '../../assets/login-background_2.jpg'
export const LoginPage = () => {
  return (
    <div
      className="container-fluid h-100 login-page"
      style={{
        background: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="row h-100" style={{}}>
        <div className="col-7 col-xxl-8 d-none d-lg-block"></div>

        <div className="col-12 col-lg-5 col-xxl-4 d-flex flex-column justify-content-center align-items-center login-col">
          <Routes>
            <Route
              path="/"
              element={
                <LoginBackground>
                  <LoginForm></LoginForm>
                </LoginBackground>
              }
            ></Route>

            <Route
              path={LOGIN_MFA_CODE.path}
              element={
                <LoginBackground>
                  <LoginMFA />
                </LoginBackground>
              }
            />

            <Route
              path={LOGIN_FORGOT_PASSWORD.path}
              element={
                <LoginBackground>
                  <ForgotPassword />
                </LoginBackground>
              }
            />
            <Route
              path={LOGIN_FORGOT_PASSWORD_SUBMIT.path}
              element={
                <LoginBackground>
                  <ForgotPasswordSubmit />
                </LoginBackground>
              }
            />
            <Route
              path={LOGIN_NEW_PASSWORD.path}
              element={
                <LoginBackground>
                  <CompleteNewPassword />
                </LoginBackground>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  )
}
