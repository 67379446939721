import { CRUD_VIEW_TYPE } from '../../Types'

export const getSubjectPiiConfig = () => {
  return subjectPiiConfig
}

const subjectPiiConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Subject name',
    label: 'Name',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'phone_number',
    type: 'TEXT',
    placeholder: 'Subject phone number',
    label: 'Phone number',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Phone number required',
    disabled: false,
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Subject email',
    label: 'Email',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
    disabled: { [CRUD_VIEW_TYPE.CREATE]: false, [CRUD_VIEW_TYPE.UPDATE]: false, [CRUD_VIEW_TYPE.VIEW]: true },
  },
]
