import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useSubjectAudit = (projectId, subjectId) => {
  const p = subjectId ? ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectId) : null
  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
