/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/05/2023.
 */

import { Alert, Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import hrt from 'human-readable-time'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormLabel } from '../../components/form/FormLabel'
import { openNotification } from '../../components/notificationToast'
import { CLINICIAN, TRIAL_STAFF } from '../../constants'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'

type Props = {
  data: any
  mutate: () => void
  userId: string
  userType: typeof TRIAL_STAFF | typeof CLINICIAN
  me: any
  handleClose: () => void
}

export const InviteTrialstaff = ({ data, mutate, userId, userType, me, handleClose }: Props) => {
  const { projectId } = useParams() as { projectId: string }
  const [reasonDescription, setReasonDescription] = useState<string>('')
  const [errorState, setErrorState] = useState<any>()

  const sendInviteClick = () => {
    const p = ENDPOINTS.TRIAL_STAFF.ACTION.sendInvite(projectId)
    console.log('InviteTrialstaff > userType = ', userType)

    post(p, { projectId: projectId, id: userId, type: userType })
      .then((result) => {
        const title = 'Invitation sent successfully'
        const message = 'Your email invitation has been sent.'

        openNotification(title, message, 'happy')

        mutate()

        handleClose()

        return result
      })
      .catch((error) => {
        console.log('TrialStaffCRUD > error = ', error)
        console.log('TrialStaffCRUD > error = ', error.message)

        alert(error.message)
        //return Promise.reject(error)
      })
  }

  const reInviteClick = () => {
    const p = ENDPOINTS.TRIAL_STAFF.ACTION.reSendInvite(projectId)

    if (reasonDescription.length < 6) {
      setErrorState({ message: 'Please provide a reason for the reset of password' })
      return
    }

    post(p, { projectId: projectId, id: userId, type: userType, reasonDescription: reasonDescription })
      .then((result) => {
        const title = 'Password reset email sent successfully'
        const message = 'A password reset email has been sent successfully.'

        openNotification(title, message, 'happy')

        mutate()

        handleClose()

        setErrorState(undefined)

        return result
      })
      .catch((error) => {
        console.log('TrialStaffCRUD > error = ', error)
        return Promise.reject(error)
      })
  }

  const inviteSent = data?.inviteSent
    ? 'Invite sent: ' + hrt(new Date(data.inviteSent), '%hh%:%mm%:%ss% on %D% %month% %YYYY%')
    : 'Invite not sent'

  const handleReason = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReasonDescription(e.target.value)
  }

  return (
    <>
      {me.type === 'CTM' ? (
        <div className="row" style={{}}>
          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 d-flex justify-content-between align-items-center my-2">
            <h6 className="mb-0">{userType === TRIAL_STAFF ? 'Invite trial staff' : 'Invite clinician'}</h6>
            <p className="p-small alpha-60 ">{inviteSent}</p>
          </div>

          {data?.inviteSent ? (
            <div className="col-12 mt-1 mb-4 ">
              <p className="mt-2">
                Click the <b>'Reset password and resent invite'</b> button to reset a Trial Staff's password and allow
                them to re-access the portal.
              </p>
              <div className="mt-2 mb-4">
                <FormLabel label="Please provide a reason for reset of password"></FormLabel>
                <TextArea
                  allowClear
                  placeholder="Justification for resetting of password"
                  rows={2}
                  onChange={handleReason}
                />
              </div>
            </div>
          ) : (
            <div className="col-12 mt-1 mb-4 ">
              <p className="">
                Click the <b>'Send Invite'</b> button to email the Trial Staff member their login credentials for
                accessing the portal for the first time.
              </p>
            </div>
          )}

          <div className="col-12 d-flex justify-content-end">
            {data?.inviteSent ? (
              <Button type="primary" onClick={reInviteClick}>
                Reset password and resend invite
              </Button>
            ) : (
              <Button type="primary" className="me-2" onClick={sendInviteClick}>
                Send invite
              </Button>
            )}
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 mb-3">
            {errorState ? (
              <div className="mb-2">
                <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
