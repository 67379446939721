import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useProjectById = (projectId: string) => {
  const p = projectId ? ENDPOINTS.PROJECTS.GET_PROJECT_BY_ID(projectId) : null
  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
