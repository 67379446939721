/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 03-08-2020.
 */
import React from 'react'

import './spinner.scss'
import { COLOR_PRIMARY } from '../../constants'

const Spinner = ({ size = 50, style = {} }) => {
  //const { error } = props
  //const statusTxt = !error ? 'Getting data...' : error

  return (
    <div
      className="loader"
      //      style={{ borderTop: `3px solid ${COLOR_PRIMARY}`, width: size, height: size, fontSize: size, ...style }}
      style={{ color: COLOR_PRIMARY, fontSize: size, ...style }}
    ></div>
  )
}

export default Spinner
