/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/11/2023.
 */
import React, { useState } from 'react'
import { AutoComplete, Button } from 'antd'
import { ReactComponent as MagIcon } from '../assets/magnify.svg'
import { Link } from 'react-router-dom'

type Props = { data: any[] }

export const SearchClinician = ({ data }: Props) => {
  const [options, setOptions] = useState<any[]>([])

  const searching = (searchTxt) => {
    const result = [
      /*{
        label: <p>Sites</p>,
        options: sitesNumberArr

          .filter((siteNum) => {
            return siteNum.indexOf(searchTxt) > -1
          })
          .map((siteNum) => {
            return renderItem(siteNum, siteNum)
          }),
      },*/
      {
        label: <p className="fw-bold ">Clinicians</p>,
        options: data
          .filter((item) => {
            return item.name.toLowerCase().indexOf(searchTxt) > -1
          })
          .map((item) => {
            return renderItem(item.name + ' - ' + item.email, item.name, item._id)
          }),
      },
    ]

    setOptions(result)
  }

  const renderItem = (title: string, value: string, id: string) => ({
    value: value,
    label: (
      <Link to={'clinician/' + id} className="d-flex justify-content-between text-black text-decoration-none">
        <p className="">{title}</p>

        <Button type="default" size="small">
          Click to view
        </Button>
      </Link>
    ),
  })

  //const onSelect = () => {}

  return (
    <div className="border py-1 rounded-5 w-100 px-3 bg-white d-flex align-items-center">
      <MagIcon className="" style={{ width: 20, height: 20 }}></MagIcon>
      <AutoComplete
        allowClear={true}
        autoClearSearchValue={true}
        options={options}
        className="w-100 py-1"
        placeholder="Search screening id, site number, etc"
        //onSearch={(text) => setOptions(getPanelValue(text))}
        onSearch={searching}
        //onSelect={onSelect}
        //bordered={false}
        variant="borderless"
      />
    </div>
  )
}
