import { getReq } from '../service/API'
import useSWR from 'swr'

//
// By 19/2 -2024 Please stop using this hook. Make a dedicated one for the endpoint
//
export const useByEndpoint = (p: string) => {
  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })
  console.warn('useByEndpoint > this hook is deprecated and should be removed ')
  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
