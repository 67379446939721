/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/09/2023.
 */
import React from 'react'
import { useLoginActivity } from '../../hooks/useLoginActivity'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { UserTypes } from '../../Types'

type AuthEventType = {
  EventId: string
  EventType: string //'SignIn'
  CreationDate: string //'2023-09-26T13:18:28.631Z'
  EventResponse: string // 'Pass'
  EventRisk: {
    RiskDecision: string //'NoRisk'
    CompromisedCredentialsDetected: false
  }
  ChallengeResponses: [
    {
      ChallengeName: string //'Password'
      ChallengeResponse: string //'Success'
    },
    {
      ChallengeName: string //'Mfa'
      ChallengeResponse: string //'Success'
    }
  ]
  EventContextData: {
    IpAddress: string // '62.243.91.180'
    DeviceName: string //'Chrome 116, Windows 10'
    City: string //'Copenhagen'
    Country: string //'Denmark'
  }
}
type Props = { type: UserTypes }

export const LoginActivity = ({ type }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const { data } = useLoginActivity(projectId, userId, type)
  console.log('LoginActivity > data = ', data)

  const hasContent = data?.AuthEvents.length > 0

  //const dayjs('2019-01-25').format('[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]')

  return (
    <div className="container-fluid ">
      {hasContent ? null : (
        <div className="row" style={{}}>
          <div className="col-12">
            <h5>Login activity</h5>
            <p>No login activity has been captured for this user.</p>
          </div>
        </div>
      )}
      <div className="row" style={{}}>
        {/*<div className="border rounded-3 ">
          <div className="py-5 pointer">
            <Empty description="User does not have access to any sites"></Empty>
            <div className="d-flex justify-content-center mt-3">
              <Button type="primary" className="me-2">
                Add site
              </Button>
            </div>
          </div>
        </div>*/}

        <div className="col-12" style={{}}>
          {data?.AuthEvents.map((item: AuthEventType, index: number) => {
            const created = dayjs(item.CreationDate).format('DD/MM/YYYY HH:mm:ss')

            return (
              <div
                key={index}
                className="row border rounded-2 px-2 py-4 mb-3 "
                style={{ boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2)' }}
              >
                <div className="col-12 mb-2">
                  <p className="opacity-75 p-small mb-1">{created}</p>
                  <h5 className="">
                    <span className="text-primary">{item.EventType}</span>
                  </h5>
                </div>

                <div className="col-6  mt-1">
                  <p className="p-small opacity-75">
                    Location <span className="opacity-75">(based on IP)</span>
                  </p>
                  <p className="text-black">{item.EventContextData.City}</p>
                  <p className="text-black">{item.EventContextData.Country}</p>
                </div>
                <div className="col-6  mt-1">
                  <p className="p-small opacity-75">Device</p>
                  <p className="text-black">{item.EventContextData.IpAddress}</p>
                  <p className="text-black">{item.EventContextData.DeviceName}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
